
export default {
  data() {
    return {
      list: [
        {
          description:
            '零售商：为有货源需求、供应链需求的中小卖家提供一站式解决方案，解决选品、配送、仓储等难题，助力卖家快速实现业绩增长。',
        },
        {
          description:
            '供货商：为有优质货源的制造商、品牌商提供全球供应链分销平台，降低运营、物流、仓储成本，实现更高效地货通全球。',
        },
      ],
    };
  },
};
