
// 组件
import LayoutHead from './components/LayoutHead';//头部
import NavBar from './components/NavBar';//侧边导航
import ContentMain from './components/ContentMain';//展示区域
export default {
  components: { LayoutHead,NavBar,ContentMain },
  data() {
    return {};
  },
};
