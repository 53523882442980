
export default {
  name: 'SubmitSuccess',
  props: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    // 点击确定按钮
    sure() {
      this.$emit('again-sure');
    },
  },
};
