import { render, staticRenderFns } from "./CommentsList.vue?vue&type=template&id=36771e8e&scoped=true"
import script from "./CommentsList.vue?vue&type=script&lang=js"
export * from "./CommentsList.vue?vue&type=script&lang=js"
import style0 from "./CommentsList.vue?vue&type=style&index=0&id=36771e8e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36771e8e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {XmPageTools: require('E:/ximu/nuxt-office3/components/xm-page-tools/XmPageTools.vue').default,XmPhonesPage: require('E:/ximu/nuxt-office3/components/xm-phones-page/XmPhonesPage.vue').default})
