
import ImageShow from '@/pages/front-desk/components/ImageShow';
export default {
  name: 'ShopActicle',
  components: { ImageShow },
  props: {
    // 指导视频路径
    url: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      pointList: [
        { name: '确定品类', id: 'sure' },
        { name: '调研市场', id: 'research' },
        { name: '选品计划', id: 'plan' },
        { name: '卖货准备', id: 'ready' },
      ],
      activityPoint: 'sure',
    };
  },
  computed: {},
  mounted() {
    let that = this;
    window.onscroll = function () {
      //scrollTop就是触发滚轮事件时滚轮的高度
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      // console.log('滚动距离' + scrollTop, that.activityPoint);
      // const list = document.querySelectorAll("a");
      if (scrollTop < 1200) {
        that.activityPoint = 'sure';
      } else if (scrollTop < 1500) {
        that.activityPoint = 'research';
      } else if (scrollTop < 1600) {
        that.activityPoint = 'plan';
      } else {
        that.activityPoint = 'ready';
      }
    };
  },
  methods: {
    // 点击锚点变化样式
    clickAnchorPointer(id) {
      this.activityPoint = id;
    },
  },
};
