
// 组件
import SingleBanner from '@/pages/front-desk/components/SingleBanner'; //单张banner组件
import Footer from '@/pages/front-desk/components/footer/Footer'; //底部
import NewsList from '../components/NewsList'; //新闻列表
export default {
  head() {
    return {
      title: this.Title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.Description,
        },
        {
          hid: 'Keywords',
          name: 'Keywords',
          content: this.Keywords,
        },
      ],
      link: [{ rel: 'icon', type: 'image/x-icon', href: 'favicon.ico' }],
    };
  },
  components: { SingleBanner, Footer, NewsList },
  data() {
    return {
      breadList: [{ name: '公司新闻', link: '' }],
    };
  },
  // 需要seo
  async asyncData(app) {
    let articleList = []; //新闻列表
    let articleListTotal = 0; //列表总数
    let bannerInfo = {}; //公司新闻的banner图

    // TDK
    let Title = '';
    let Keywords = '';
    let Description = '';
    try {
      // 公司新闻
      const articleListOptions = {
        classify: 'dynamic_company',
        pageNum: 1,
        pageSize: 10,
      };
      const [resArticleList, resBanner, resTdk] = await Promise.all([
        // 公司新闻
        app.$getArticleList(articleListOptions),
        // 公司新闻banner
        app.$getBanner({ classify: 'dynamic_company' }),
        // 获取TDK
        app.$getSeoInfo({ id: '30' }),
      ]);
      // 公司新闻
      if (resArticleList?.code == 1) {
        articleList = resArticleList?.data?.list || [];
        articleListTotal = resArticleList?.data?.total || 0;
      }
      // 公司新闻banner
      if (resBanner?.code == 1) {
        bannerInfo = resBanner?.data?.[0] || {};
      }
      // 获取页面TDK
      if (resTdk?.code == 1) {
        let info = resTdk?.data?.[0] || {};
        Title = info?.title || '';
        Description = info?.description || '';
        Keywords = info?.keyword || '';
      }
      return {
        articleList,
        articleListTotal,
        bannerInfo,
        Title,
        Keywords,
        Description,
      };
    } catch (error) {
      console.error(error);
      if (error?.status) {
        // 请求报错
        console.log(
          'front-desk/industry-trends/company-news/index.vue',
          error?.status,
          error?.statusText
        );
      } else {
        // 代码报错
        console.log(error);
      }
      return {
        articleList,
        articleListTotal,
        bannerInfo,
        Title,
        Keywords,
        Description,
      };
    }
  },
};
