
// api
import { getArticleList } from '@/api/client-api/front_desk_api.js';
export default {
  name: 'newslist',
  data() {
    return {
      curNewlist: this.list || [], //渲染页面的列表数据
      curTotle: this.total || 0, //渲染页面的列表总数
      // 区分分页类型
      pageToolsType: '2', //是否为手机 1-手机 2-pc,平板类型
      pageNum: 1,
      pageSize: 10,
    };
  },
  props: {
    // 列表数据
    list: {
      type: Array,
      default: () => [],
    },
    // 列表总数
    total: {
      type: Number,
      default: 0,
    },
    // 用哪一个列表数据
    // dynamic_domestic-国内新闻
    newsType: {
      type: String,
      default: '',
    },
  },
  methods: {
    /**
     * 页面触发
     */
    // 获取a链接 #TODO 这里要统一
    getlink(item) {
      const options = [
        { type: 'policy_dynamics', link: '/zc/' },
        { type: 'dynamic_domestic', link: '/gn/' },
        { type: 'dynamic_border', link: '/kj/' },
        { type: 'dynamic_company', link: '/newsdetails/' },
      ];
      for (let i = 0; i < options.length; i++) {
        if (this.newsType == options[i].type) {
          return options[i].link + item.id;
        }
      }
    },
    // 分页切换事件
    goPage(val) {
      this.pageNum = val;
      this.scrollToTop();
      this.getDataList();
    },
    // 获取列表数据
    async getDataList() {
      const options = {
        classify: this.newsType,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      let res = await getArticleList(options);
      if (res?.code == 1) {
        this.curNewlist = res?.data?.list || [];
        this.curTotle = res?.data?.total || 0;
      }
    },
    checkScreenWidth() {
      const screenWidth = window.innerWidth;
      if (screenWidth < 769) {
        this.pageToolsType = '1';
      } else {
        this.pageToolsType = '2';
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.checkScreenWidth();
      window.addEventListener(
        'resize',
        this._throttle(this.checkScreenWidth, 150)
      );
    });
  },
  beforeDestroy() {
    if (process.client) {
      window.removeEventListener('resize', this.checkScreenWidth);
    }
  },
};
