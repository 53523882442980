import { render, staticRenderFns } from "./OperationTable.vue?vue&type=template&id=1151e11b&scoped=true"
import script from "./OperationTable.vue?vue&type=script&lang=js"
export * from "./OperationTable.vue?vue&type=script&lang=js"
import style0 from "./OperationTable.vue?vue&type=style&index=0&id=1151e11b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1151e11b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {XmVideo: require('E:/ximu/nuxt-office3/components/xm-video/XmVideo.vue').default,XmPreview: require('E:/ximu/nuxt-office3/components/xm-preview/XmPreview.vue').default})
