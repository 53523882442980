
export default {
  name: 'XimuDialog',
  props: {
    title: {
      type: String,
      default: '默认标题',
    },
    //是否要关闭图标
    isclose: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    close() {
      this.$emit('close-dialog'); // 父组件绑定的事件
    },
  },
};
