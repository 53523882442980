
export default {
  name: 'XmBreadcrumb',
  props: {
    /**
     * 除首页外的面包屑数组
     * {name: '', link: ''}
     * name: 文字
     * link: 需要跳转的路径，不跳转传''
     */
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  computed: {
    breadList() {
      let beforeList = [{ name: '首页', link: '/' }];
      return beforeList.concat(this.list);
    },
  },
};
