
// 组件
import ChangeBox from './ChangeBox.vue';
export default {
  components: { ChangeBox },
  data() {
    return {};
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
};
