
// 变量
import { setBannerField } from './js/tablefield';
// api
import { deleteBanner, editBanner } from '@/api/client-api/backstageapi.js';
export default {
  props: {
    // 表格数据
    tableData: {
      type: Array,
      default: () => [],
    },
    /**
     * 当前表格属于的板块
     * 用于当前页面有多个banner表格时传值区分需要刷新哪一项表格数据
     */
    type: {
      type: String,
      default: '',
    },
    // 如果值不为-1  则有最大值限制
    maxNum: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      oldData: this.tableData,
      // 弹框
      hasDoubleCheckDialog: false, //控制二次确认弹框
      rowInfo: {}, //记录选中的表格数据
      content: '您确定要删除吗？',

      // 预览
      hasPreview: false, //控制预览弹框
      previewLink: '', //需要预览的数据
    };
  },
  watch: {
    tableData(n) {
      if (n) {
        this.oldData = JSON.parse(JSON.stringify(n));
      }
    },
  },
  computed: {
    // 当前表格的配置
    curTableFiled() {
      return setBannerField;
    },
    // 当前的表格信息
    curTableData() {
      return this.tableData;
    },
  },
  mounted() {},
  methods: {
    /**
     * 页面触发
     */
    // 点击图片预览
    handlePreview(link) {
      this.previewLink = link;
      this.hasPreview = true;
    },
    // 关闭预览
    handleClosePreview() {
      this.hasPreview = false;
      this.previewLink = '';
    },
    // 点击“编辑”
    handleEditor(row) {
      this.$emit('editor-banner', row, this.type);
    },
    // 点击“删除”
    handleDelete(row) {
      // 如果列表中只有一个的情况，不给删除
      if (this.curTableData?.length <= 1) {
        this.$message.warning('至少保留一条数据');
        return;
      }
      // 如果删除的是发布状态的
      if (row?.switch) {
        if (!this.checkSwitch(false)) {
          this.content = '您至少保留一个发布的Banner图，如果删了会发布其中一个';
        }
      }
      this.rowInfo = row;
      this.hasDoubleCheckDialog = true;
    },
    // 二次确认弹框取消按钮
    handleCloseDia() {
      this.rowInfo = {};
      this.hasDoubleCheckDialog = false;
      this.content = '您确定要删除吗？';
    },
    // 二次确认弹框确认按钮
    async handleConfigDia() {
      const options = {
        id: this.rowInfo.id,
      };
      try {
        let res = await deleteBanner(options);
        if (res?.code == 1) {
          this.$message.success(res.msg);
          this.$emit('up-data', this.type, 'banner');
          this.handleCloseDia();
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 修改发布状态
    async changeSwitch(row, isSwitch) {
      // 改变显示状态前，先锁定状态值
      for (let i = 0; i < this.oldData.length; i++) {
        if (row.id == this.oldData[i].id) {
          row.switch = this.oldData[i].switch;
          break;
        }
      }
      // 锁定完状态值才进行相对应的操作
      // 首先判断是否能修改状态
      let info = this.checkSwitch(isSwitch);
      if (!info.type) {
        this.$message.warning(info.msg);
        return;
      }
      // 进行正常的修改状态
      const options = {
        id: row.id,
      };
      if (row.switch) {
        // 目前发布 -> 不发布
        options.status = '0';
      } else {
        // 目前不发布 -> 发布
        options.status = '1';
      }
      try {
        let res = await editBanner(options);
        if (res?.code == 1) {
          this.$message.success(res.msg);
          this.$emit('up-data', this.type, 'banner');
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 检查发布状态的是否只剩一个了
    // isSwitch 是修改操作当前的需要去到的状态，例如true -> false
    checkSwitch(isSwitch) {
      let openSwitch = this.curTableData?.filter(
        (item) => item.switch && item.status == 1
      );
      console.log(openSwitch.length - 1,'openSwitch');
      if (!isSwitch) {
        if (openSwitch.length <= 1) {
          return { type: false, msg: '操作无效，至少要有一个显示的banner图' };
        }
      } else {
        if (this.maxNum != -1 && (openSwitch.length + 1) > this.maxNum) {
          return { type: false, msg: '操作无效，只能展示六张banner' };
        }
      }
      return { type: true, msg: '' };
    },
  },
};
