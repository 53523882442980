
// 板块组成变量
import { learningCenter } from './js/learning_center';
// 组件
import BannerTable from '../components/banner-table/BannerTable.vue'; // banner表格
import CateShowBox from '../components/CateShowBox.vue'; // 品类盒子
import ActicleTable from '../components/news-details/NewsDetails'; // 文章表格
import OperationLog from '@/pages/back-stage/components/operation-Log/OperationLog.vue'; //操作日志

import VideoBanner from '../components/ImgBanner.vue'; // 操作视频banner设置
// 弹窗
import AddBanner from '../components/AddBanner.vue';
import AddActicle from '../components/AddActicle.vue';
// api
import {
  getBannerList,
  editClassifyType,
} from '@/api/client-api/backstageapi.js';
import {
  getActicleList,
  getClassifyType,
} from '@/api/client-api/learning_center.js';
export default {
  name: 'LearningCenter',
  components: {
    BannerTable,
    CateShowBox,
    ActicleTable,
    VideoBanner,
    AddBanner,
    AddActicle,
    OperationLog,
  },
  data() {
    return {
      mainList: learningCenter,

      hasAddBanner: false, // 新增/编辑banner弹窗状态
      hasAddActicle: false, // 新增/编辑文章弹窗状态
      dialogTitle: '新增选品推荐banner', // 通用弹窗标题
      bannerLength: 0, // 当前操作项的数组长度(banner图要用)

      actionClassify: '', // 正在操作的板块,接口需要的
      rowInfo: {}, // 选中需要编辑banner行

      recommendBannerList: [], // 首页推荐banner列表
      dryInfoBannerList: [], // 运营干货banner列表

      recommendActicleList: [], // 选品推荐文章
      dryInfoActicleList: [], // 运营干货文章
      videoList: [], // 操作视频列表

      // 选品推荐文章分页
      recommendPage: {
        page: 1,
        limit: 10,
        count: 0,
      },
      // 运营干货文章分页
      dryInfoPage: {
        page: 1,
        limit: 10,
        count: 0,
      },
      // 操作视频文章分页
      videoPage: {
        page: 1,
        limit: 10,
        count: 0,
      },

      recommendCateEdit: '', // 选品推荐品类操作状态 ''不在可操作状态 edit编辑 move移动
      dryInfoCateEdit: '', // 运营干货品类操作状态 ''不在可操作状态 edit编辑 move移动
      recommendCateList: [], // 选品推荐品类列表
      dryInfoCateList: [], // 运营干货品类列表
    };
  },
  mounted() {
    this.getBannerList('learning_recommend', 'recommendBannerList');
    this.getCateList('learning_recommend', 'recommendCateList');
    this.getActicleList(
      'learning_recommend',
      'recommendActicleList',
      'recommendPage'
    );
    this.getBannerList('learning_operating', 'dryInfoBannerList');
    this.getCateList('learning_operating', 'dryInfoCateList');
    this.getActicleList(
      'learning_operating',
      'dryInfoActicleList',
      'dryInfoPage'
    );
    this.getActicleList('learning_video', 'videoList', 'videoPage');
  },
  methods: {
    /**
     * 获取banner列表
     * @param {String} type 后端需要的类型值
     * @param {Array} name data中定义需要赋值的列表名称
     */
    async getBannerList(type, name) {
      try {
        let options = {
          filter: JSON.stringify({ classify: type }),
        };
        const res = await getBannerList(options);
        if (res?.code == 1) {
          let list = JSON.parse(JSON.stringify(res?.data?.data || []));
          list.forEach((item) => {
            item.table_type = 'banner';
            // 初始化
            item.switch = false;
            if (item.status == 1) {
              item.switch = true;
            }
          });
          this[name] = list;
        }
      } catch (error) {
        console.error(error);
      }
    },
    /**
     * 获取文章列表
     * @param {String} cat_type 后端需要的类型值
     * @param {Array} name data中定义需要赋值的列表名称
     * @param {Object} type data中定义的分页数据名称
     */
    async getActicleList(cat_type, name, type) {
      try {
        let options = {
          page: this[type].page,
          limit: this[type].limit,
          cat_type,
        };
        const res = await getActicleList(options);
        if (res?.code == 1) {
          let list = JSON.parse(JSON.stringify(res?.data?.data || []));
          list.forEach((item) => {
            item.table_type = 'acticle';
            // 初始化
            item.switch = false;
            if (item.status == 1) {
              item.switch = true;
            }
          });
          this[name] = list;
          this[type].count = Number(res?.data?.count) || 0;
        }
      } catch (error) {
        console.error(error);
      }
    },
    /**
     * 获取品类列表
     * @param {*} type 后端需要的类型值
     * @param {*} name data中定义需要赋值的列表名称
     */
    async getCateList(type, name) {
      try {
        const res = await getClassifyType({ classify: type });
        if (res?.code == 1) {
          this[name] =
            res?.data?.map((obj) => {
              return { id: obj.id, name: obj.name, errTipFont: '' };
            }) || [];
        }
      } catch (err) {
        console.error(err);
      }
    },
    /**
     * 点击新增按钮
     * @param {Object} item 区分新增类型
     */
    clickAddBtn(item) {
      this.actionClassify = item?.cate_menu;
      this.dialogTitle = '新增' + item?.dialog_title; // 新增内容弹窗标题
      if (
        item?.anchorpoint == 'recommendBanner' ||
        item?.anchorpoint == 'dryInfoBanner'
      ) {
        this.bannerLength = this[item?.anchorpoint + 'List']?.length || 0;
      }
      if (item?.table_type == 'banner') this.hasAddBanner = true;
      if (item?.table_type == 'acticle') this.hasAddActicle = true;
    },
    // 点击关闭弹窗
    handleCancel() {
      this.hasAddBanner = false; // 关闭新增/编辑banner弹窗
      this.hasAddActicle = false;
      this.dialogTitle = '';
      this.bannerLength = 0;
      this.rowInfo = {};
      this.actionClassify = '';
    },
    /**
     * 需要重新获取数据
     * 删除banner表格数据、更新banner/文章发布状态
     * @param {String} type 区分需要重新获取的列表数据
     * @param {String} row 值为banner/acticle,为了区分更新哪类列表
     */
    handleConfirm(type, row) {
      if (row == 'banner') {
        if (type == 'learning_recommend') {
          this.getBannerList(type, 'recommendBannerList');
        }
        if (type == 'learning_operating') {
          this.getBannerList(type, 'dryInfoBannerList');
        }
      }
      if (row == 'acticle') {
        if (type == 'learning_recommend') {
          this.getActicleList(type, 'recommendActicleList', 'recommendPage');
        }
        if (type == 'learning_operating') {
          this.getActicleList(type, 'dryInfoActicleList', 'dryInfoPage');
        }
        if (type == 'learning_video') {
          this.getActicleList(type, 'videoList', 'videoPage');
        }
      }

      this.handleCancel(); // 成功后关闭弹窗
    },
    /**
     * 编辑banner表格数据、编辑文章表格数据
     * @param {Object} row 点击编辑那行的表格数据,row.table_type区分打开哪类弹窗
     * @param {String} type 区别弹窗的文字
     */
    handleEditorTable(row, type) {
      this.rowInfo = row;
      this.actionClassify = type;
      if (row?.table_type == 'banner') {
        if (type == 'learning_recommend') {
          this.dialogTitle = '编辑选品推荐banner'; // 编辑banner弹窗标题
          this.bannerLength = this.recommendBannerList?.length || 0;
        }
        if (type == 'learning_operating') {
          this.dialogTitle = '编辑运营干货banner'; // 编辑banner弹窗标题
          this.bannerLength = this.dryInfoBannerList?.length || 0;
        }
        this.hasAddBanner = true; // 打开编辑banner弹窗
      }
      if (row?.table_type == 'acticle') {
        if (type == 'learning_recommend') {
          this.dialogTitle = '编辑选品推荐文章'; // 编辑文章弹窗标题
        }
        if (type == 'learning_operating') {
          this.dialogTitle = '编辑运营干货文章'; // 编辑文章弹窗标题
        }
        if (type == 'learning_video') {
          this.dialogTitle = '编辑操作视频';
        }
        this.hasAddActicle = true; // 打开编辑文章弹窗
      }
    },
    /**
     * 跳转分页(只有文章有分页)
     * @param {String} page 需要跳转到第page页
     * @param {Number} type 区分需要重新获取哪个文章表格
     */
    goPages(page, type) {
      if (type == 'learning_recommend') {
        this.recommendPage.page = Number(page);
        this.getActicleList(type, 'recommendActicleList', 'recommendPage');
      }
      if (type == 'learning_operating') {
        this.dryInfoPage.page = Number(page);
        this.getActicleList(type, 'dryInfoActicleList', 'dryInfoPage');
      }
      if (type == 'learning_video') {
        this.videoPage.page = Number(page);
        this.getActicleList(type, 'videoList', 'videoPage');
      }
    },
    /**
     * 删除文章列表数据
     * @param {String} type 区分需要重新获取哪个文章表格
     */
    delTableData(type) {
      if (type == 'learning_recommend') {
        if (this.recommendActicleList.length == 1) {
          this.recommendPage.page--;
        }
        this.getActicleList(type, 'recommendActicleList', 'recommendPage');
      }
      if (type == 'learning_operating') {
        if (this.dryInfoActicleList.length == 1) {
          this.dryInfoPage.page--;
        }
        this.getActicleList(type, 'dryInfoActicleList', 'dryInfoPage');
      }
      if (type == 'learning_video') {
        if (this.videoList.length == 1) {
          this.videoPage.page--;
        }
        this.getActicleList(type, 'videoList', 'videoPage');
      }
    },
    /**
     * 点击品类的按钮们
     * @param {*} type 当前操作品类类型 recommendCate 选品推荐品类，dryInfoCate 运营干货品类
     * @param {*} editOpera edit 编辑，move 移动，'' 完成/取消  (传入组件可操作类型，切换按钮组展示)
     * @param {*} saveOpera finish 完成，cancel 取消，'' 编辑/移动  (完成就传出数据再重新获取，取消直接重新获取)
     */
    operateCate(type, editOpera, saveOpera) {
      if (saveOpera != 'finish') this[type + 'Edit'] = editOpera;
      if (saveOpera == 'finish') {
        this.$refs[type + 'ShowBox'][0].editFinish();
      }
    },
    /**
     * 编辑/移动品类点击完成,传出数据
     * @param {String} type  操作品类类型(自用) recommendCate 选品推荐品类，dryInfoCate 运营干货品类
     * @param {String} cateMenu 品类类型(后端用) learning_recommend 选品推荐品类, learning_operating 运营干货品类
     * @param {Array} list 新的品类数据
     */
    async editCateFinish(type, cateMenu, list) {
      try {
        let options = {
          classify: cateMenu,
          data: list,
        };
        const res = await editClassifyType(options);
        if (res?.code == '1') {
          if (res?.msg) this.$message.success(res?.msg);
          await this.getCateList(cateMenu, type + 'List'); // 重新获取品类数据
          this[type + 'Edit'] = '';
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
};
