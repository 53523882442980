// 设置首页Banner图表格配置
export const setHotKey = [
  {
    text: '热搜词排序',
    filed: 'sort',
    width: '150px',
    isSort: false,
    isEdit: false,
  },
  {
    text: '热搜词',
    filed: 'words',
    width: '160px',
    isSort: false,
    isEdit: false,
  },
  {
    text: '操作',
    filed: 'operation',
    width: '150px',
    isSort: false,
    isEdit: true,
  },
];
