
// 组件
import PlatformRulesTabs from './components/PlatformRulesTabs'; //标签组件
import OperateBox from './components/OperateBox'; //搜索/新增文章/操作日志 容器
import RulesTable from './components/RulesTable'; //规则表格
import EditorRulesType from './components/EditorRulesType'; //新增/编辑分类
import AddRules from './components/AddRules';
// api
import {
  getRulesCateList,
  getActicleList,
  delCate,
  delActicle,
  editActicle,
} from '@/api/client-api/learning_center.js';
export default {
  components: {
    PlatformRulesTabs,
    OperateBox,
    RulesTable,
    EditorRulesType,
    AddRules,
  },
  data() {
    return {
      activeName: '', //tabs选中的
      tabsList: [], //标签列表
      tabsInfo: {}, //当前标签的数据
      rulesList: [], // 规则列表
      // 搜索
      statusVal: '', //状态值
      // 弹框
      rulesTitle: this.curRulesTitle, //分类弹框标题
      hasEditorRulesDialog: false, //控制分类弹框
      hasDoubleCheckDialog: false, //控制二次确认弹框(分类)
      content: '您确定要删除吗？',

      // 文章弹框
      addRulesDialog: false, // 文章弹框状态
      rulesDialogTitle: '', // 规则弹框标题
      rowInfo: {}, // 选中的表格行数据
      hasDoubleCheckTypeDialog: false, //控制二次确认弹框(规则)

      // 分页相关
      total: 0,
      pageSize: 10,
      pageNum: 1,
    };
  },
  props: {
    // 当前弹框的Title/父组件打开弹框用的
    curRulesTitle: {
      type: String,
      default: '新增分类',
    },
    curHasEditorRulesDislog: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    curHasEditorRulesDislog(nVal) {
      if (nVal) {
        this.rulesTitle = '新增分类';
        this.hasEditorRulesDialog = nVal;
      }
    },
  },
  mounted() {
    this.getCategoryList(); // 获取分类列表
  },
  methods: {
    /**
     * 页面触发
     */
    // 搜索
    changeSearch(val) {
      this.statusVal = val;
      this.getRulesActicleList();
    },
    // 修改规则开关
    async changeSwitch(row) {
      const options = {
        classify: 'cate',
        id: row.id,
        cate_id: row.cate_id,
      };
      if (row?.home_page_status == 0) {
        options.home_page_status = 1;
      } else if (row?.home_page_status == 1) {
        options.home_page_status = 0;
      }
      let res = await editActicle(options);
      if (res?.code == 1) {
        this.$message.success(res.msg);
        this.getRulesActicleList(); // 获取规则列表
      }
    },
    // 分类相关
    // 获取规则类型列表
    async getCategoryList() {
      try {
        const res = await getRulesCateList();
        if (res?.code == 1) {
          this.tabsList = res?.data;
          if (!this.activeName) {
            this.activeName = this.tabsList?.[0]?.id?.toString() || '';
          }
          this.getRulesActicleList(); // 获取规则列表
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 标签点击事件
    handleClick(active) {
      this.activeName = active;
      this.pageNum = 1;
      if (this.$refs.operatebox) {
        this.$refs.operatebox.reChange();
      }
      // this.getRulesActicleList(); // 重新获取规则列表数据
    },
    // 删除标签分类
    handleRemoveTabs(tabs) {
      this.tabsInfo = tabs;
      this.hasDoubleCheckDialog = true;
    },
    // 关闭二次确认弹框
    handleCloseDia() {
      this.tabsInfo = {};
      this.hasDoubleCheckDialog = false;
    },
    // 二次确认弹框确认按钮
    async handleConfigDia() {
      const options = {
        id: this.tabsInfo.id,
      };
      let res = await delCate(options);
      if (res?.code == 1) {
        this.$message.success(res.msg);
        if (this.activeName == this.tabsInfo.id) {
          this.activeName = '';
        }
        this.getCategoryList();
        this.handleCloseDia();
      }
    },
    // 关闭分类标题弹框
    closeRulesTypeDialog() {
      this.$emit('close-Rules-Dislog');
      this.tabsInfo = {};
      this.hasEditorRulesDialog = false;
    },
    // 点击“编辑分类”
    handleEditorType() {
      this.rulesTitle = '编辑分类';
      this.tabsInfo =
        this.tabsList?.filter((item) => item.id == this.activeName)?.[0] || {};
      this.hasEditorRulesDialog = true;
    },
    // --------------------------------------------------------------------------
    // 获取规则列表
    async getRulesActicleList() {
      try {
        let options = {
          cat_type: 'cate',
          cate_id: this.activeName,
          page: this.pageNum,
          limit: this.pageSize,
        };
        if (this.statusVal) {
          options.status = this.statusVal;
        }
        const res = await getActicleList(options);
        if (res?.code == 1) {
          this.rulesList = res?.data?.data;
          this.total = Number(res?.data?.count) || 0;
        }
      } catch (error) {
        console.error(error);
      }
    },
    /**
     * 规则弹框
     */
    // 点击新增规则按钮
    openAddRulesDialog() {
      this.rulesDialogTitle = '新增规则';
      this.addRulesDialog = true;
    },
    // 点击关闭规则弹框
    closeRulesDialog() {
      this.addRulesDialog = false;
      this.rulesDialogTitle = '';
      this.rowInfo = {};
    },
    // 确认新增/编辑规则
    handleRulesConfirm() {
      this.closeRulesDialog(); // 关闭弹框
      this.getCategoryList(); // 重新获取分类列表
      this.getRulesActicleList(); // 重新获取规则列表
    },
    // 打开编辑规则文章弹框
    openEditRulesDialog(row) {
      this.rulesDialogTitle = '编辑规则';
      this.rowInfo = row;
      this.addRulesDialog = true;
    },
    // 点击删除规则
    handleDeleteRules(row) {
      this.rowInfo = row;
      this.hasDoubleCheckTypeDialog = true;
    },
    // 确认按钮
    async handleConfigTypeDia() {
      const options = {
        id: this.rowInfo.id,
      };
      try {
        let res = await delActicle(options);
        if (res?.code == 1) {
          this.$message.success(res.msg);
          if (this.pageNum > 1 && this.rulesList?.length == 1) {
            this.pageNum = this.pageNum - 1;
          }
          this.getCategoryList();
          this.handleCloseTypeDia();
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 取消
    handleCloseTypeDia() {
      this.rowInfo = {};
      this.hasDoubleCheckTypeDialog = false;
    },
    // 分页相关
    goRulesPage(n) {
      this.pageNum = Number(n);
      this.getRulesActicleList(); // 重新获取规则列表
    },
  },
};
