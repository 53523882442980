import { render, staticRenderFns } from "./CateShowBox.vue?vue&type=template&id=d27577c0&scoped=true"
import script from "./CateShowBox.vue?vue&type=script&lang=js"
export * from "./CateShowBox.vue?vue&type=script&lang=js"
import style0 from "./CateShowBox.vue?vue&type=style&index=0&id=d27577c0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d27577c0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DoubleCheckDialog: require('E:/ximu/nuxt-office3/components/double-check-dialog/DoubleCheckDialog.vue').default})
