
// 板块组成变量
import { storeStory } from './js/store_story';
// 组件
import ActicleTable from '../components/news-details/NewsDetails.vue'; // 文章表格
import OperationLog from '@/pages/back-stage/components/operation-Log/OperationLog.vue'; //操作日志

// 弹窗
import AddActicle from '../components/AddActicle.vue';
// api
import { getActicleList } from '@/api/client-api/learning_center.js';
export default {
  name: 'LearningCenter',
  components: {
    ActicleTable,
    AddActicle,
    OperationLog,
  },
  data() {
    return {
      mainList: storeStory,

      hasAddActicle: false, // 新增/编辑文章弹窗状态
      dialogTitle: '新增商家故事', // 通用弹窗标题
      rowInfo: {}, // 选中需要编辑banner行
      storyList: [], // 选品推荐文章

      // 商家故事文章分页
      storyPage: {
        page: 1,
        limit: 10,
        count: 0,
      },
    };
  },
  mounted() {
    this.getActicleList(); // 获取商家故事文章列表
  },
  methods: {
    // 获取文章列表
    async getActicleList() {
      try {
        let options = {
          page: this.storyPage.page,
          limit: this.storyPage.limit,
          cat_type: 'store_story',
        };
        const res = await getActicleList(options);
        if (res?.code == 1) {
          let list = JSON.parse(JSON.stringify(res?.data?.data || []));
          list.forEach((item) => {
            // 初始化
            item.switch = false;
            if (item.status == 1) {
              item.switch = true;
            }
            // 置顶状态
            item.topSwitch = false;
            if (item.top_status == 1) {
              item.topSwitch = true;
            }
          });
          this.storyList = list;
          this.storyPage.count = Number(res?.data?.count) || 0;
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 点击新增按钮
    clickAddBtn() {
      this.dialogTitle = '新增商家故事';
      this.hasAddActicle = true;
    },
    // 点击关闭弹窗
    handleCancel() {
      this.hasAddActicle = false;
      this.dialogTitle = '';
      this.rowInfo = {};
    },
    // 需要重新获取数据
    handleConfirm() {
      this.getActicleList();
      this.handleCancel(); // 成功后关闭弹窗
    },
    /**
     * 点击表格中编辑按钮
     * @param {Object} row 需要编辑的表格行数据
     */
    handleEditorTable(row) {
      this.rowInfo = row;
      this.dialogTitle = '编辑商家故事';
      this.hasAddActicle = true; // 打开编辑文章弹窗
    },
    // 跳转分页
    goPages(page) {
      this.storyPage.page = Number(page);
      this.getActicleList();
    },
    // 删除列表数据
    delTableData() {
      if (this.storyList.length == 1) {
        this.storyPage.page--;
      }
      this.getActicleList();
    },
  },
};
