
export default {
  data() {
    return {
      // 收集信息数据，字段名根据后端返回决定
      fileConfig: {
        'create_time':'提交时间',
        'type':'分类',
        'phone':'手机号',
        'company':'公司名称',
        'content':'建议内容',
        'remark':'备注',
      },
    };
  },
  props: {
    // 表格选中的数据
    dataInfo: {
      type: Object,
      default:()=>{}
    }
  }
};
