import { render, staticRenderFns } from "./NewsDetails.vue?vue&type=template&id=3d5711a9&scoped=true"
import script from "./NewsDetails.vue?vue&type=script&lang=js"
export * from "./NewsDetails.vue?vue&type=script&lang=js"
import style0 from "./NewsDetails.vue?vue&type=style&index=0&id=3d5711a9&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d5711a9",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {XmPaged: require('E:/ximu/nuxt-office3/components/xm-paged/XmPaged.vue').default,DoubleCheckDialog: require('E:/ximu/nuxt-office3/components/double-check-dialog/DoubleCheckDialog.vue').default,XmPreview: require('E:/ximu/nuxt-office3/components/xm-preview/XmPreview.vue').default})
