
// 组件
import SingleBanner from '@/pages/front-desk/components/SingleBanner.vue'; //单张banner组件
import Footer from '@/pages/front-desk/components/footer/Footer'; //底部
import NoticeChange from './components/NoticeChange'; //公告
import PlateContent from './components/PlateContent';
export default {
  head() {
    return {
      title: this.Title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.Description,
        },
        {
          hid: 'Keywords',
          name: 'Keywords',
          content: this.Keywords,
        },
      ],
      link: [{ rel: 'icon', type: 'image/x-icon', href: 'favicon.ico' }],
    };
  },
  components: {
    SingleBanner,
    Footer,
    NoticeChange,
    PlateContent,
  },
  data() {
    return {
      breadList: [{ name: '经营支持', link: '' }],
    };
  },
  // 需要seo的
  /**
   *
   * @param {Object} app 相当于vue实例
   */
  async asyncData(app) {
    let bannerInfo = {}; //意见征集的banner图

    // TDK
    let Title = '';
    let Keywords = '';
    let Description = '';
    try {
      const [resBanner, resTdk] = await Promise.all([
        // 意见征集banner
        app.$getBanner({ classify: 'manage_support' }),
        // 获取TDK
        app.$getSeoInfo({ id: '35' }),
      ]);
      // 意见征集banner
      if (resBanner?.code == 1) {
        bannerInfo = resBanner?.data?.[0] || {};
      }
      // 获取页面TDK
      if (resTdk?.code == 1) {
        let info = resTdk?.data?.[0] || {};
        Title = info?.title || '';
        Description = info?.description || '';
        Keywords = info?.keyword || '';
      }
      return {
        bannerInfo,
        Title,
        Keywords,
        Description,
      };
    } catch (error) {
      console.error(error);
      if (error?.status) {
        // 请求报错
        console.log(
          'front-desk/business-support/index.vue',
          error?.status,
          error?.statusText
        );
      } else {
        // 代码报错
        console.log(error);
      }
      return {
        bannerInfo,
        Title,
        Keywords,
        Description,
      };
    }
  },
};
