import { render, staticRenderFns } from "./BannerTable.vue?vue&type=template&id=b5686a04&scoped=true"
import script from "./BannerTable.vue?vue&type=script&lang=js"
export * from "./BannerTable.vue?vue&type=script&lang=js"
import style0 from "./BannerTable.vue?vue&type=style&index=0&id=b5686a04&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b5686a04",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DoubleCheckDialog: require('E:/ximu/nuxt-office3/components/double-check-dialog/DoubleCheckDialog.vue').default,XmPreview: require('E:/ximu/nuxt-office3/components/xm-preview/XmPreview.vue').default})
