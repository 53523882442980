
import ImageShow from '@/pages/front-desk/components/ImageShow.vue';
export default {
  name: 'TopStoryShow',
  components: { ImageShow },
  props: {
    // 置顶的商家故事列表
    videoTopList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {},
};
