
export default {
  name: 'newslist',
  data() {
    return {
      curNewlist: this.list || [], //渲染页面的列表数据
    };
  },
  props: {
    // 列表数据
    list: {
      type: Array,
      default: () => [],
    },
    // 用哪一个列表数据
    // dynamic_domestic-国内新闻
    newsType: {
      type: String,
      default: '',
    },
  },
  methods: {
    /**
     * 页面触发
     */
    // 获取a链接
    getlink(item) {
      const options = [
        { type: 'policy_dynamics', link: '/zc/' },
        { type: 'dynamic_domestic', link: '/gn/' },
        { type: 'dynamic_border', link: '/kj/' },
        { type: 'dynamic_company', link: '/newsdetails/' },
      ];
      for (let i = 0; i < options.length; i++) {
        if (this.newsType == options[i].type) {
          return options[i].link + item.id;
        }
      }
    },
  },
};
