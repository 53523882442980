
// 组件
import BasicEditor from '@/pages/back-stage/components/BasicEditor';
// api
import {
  addActicle,
  editActicle,
  getRulesCateList,
  getActicleDetails,
} from '@/api/client-api/learning_center.js';
export default {
  name: 'AddRules',
  components: { BasicEditor },
  props: {
    // 表格选中的数据
    rowInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      info: {
        category: '', // 分类
        title: '', // 规则标题
        status: '', // 设置状态
        publishTime: '', // 发布时间
        mainText: '', // 规则正文
        remark: '', //备注
      },
      categoryList: [], // 品类/分类列表

      editorStatus: 'add', // 富文本状态 add/edit
      acticleKey: 0, // 富文本获取数据需要
    };
  },
  mounted() {
    this.getCategoryList(); // 获取规则类型列表
    // id存在,编辑弹窗
    if (this.rowInfo?.id) {
      this.editorStatus = 'edit';
      this.getActicleDeatil(); // 获取文章详情
    }
  },
  methods: {
    // 获取规则类型列表
    async getCategoryList() {
      try {
        const res = await getRulesCateList();
        if (res?.code == 1) {
          this.categoryList = res?.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 富文本内容改变
    editorChange(val) {
      this.info.mainText = val;
    },
    /**
     * 页面触发
     */
    // 获取文章详情
    async getActicleDeatil() {
      try {
        const res = await getActicleDetails({ id: this.rowInfo?.id });
        if (res?.code == 1) {
          const options = {
            category: '', // 分类
            title: '', // 规则标题
            status: '', // 设置状态
            publishTime: '', // 发布时间
            mainText: '', // 规则正文
            remark: '', //备注
          };
          this.info = JSON.parse(JSON.stringify(this.rowInfo || options));
          this.$set(this.info, 'category', this.rowInfo?.cate_id || ''); // 分类
          this.$set(this.info, 'publishTime', this.rowInfo?.publish_time || ''); // 发布时间
          this.$set(this.info, 'mainText', res?.data?.content || ''); // 规则正文
          // 设置状态
          if (this.rowInfo?.status == 1) {
            this.info.status = '1';
          } else {
            this.info.status = '0';
          }
          this.acticleKey++;
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 上传成功回调
    handleUploadFile(link) {
      this.info.cover = link;
    },
    // 点击“确定”
    async handleConfirm() {
      try {
        let mainText = this.info?.mainText
          ?.replace(/<(?!img)(?!video)[^>]+>/g, '')
          ?.trim();
        if (
          !this.info?.category ||
          !this.info?.title ||
          !this.info?.status ||
          !this.info?.remark ||
          !this.info?.publishTime ||
          !mainText
        ) {
          this.$message.error('还有必填没填');
          return;
        }
        const options = {
          cate_id: this.info?.category, // 规则分类
          title: this.info?.title, // 规则标题
          status: this.info?.status, // 设置状态 0无效 1有效
          publish_time: this.info?.publishTime, // 发布时间
          remark: this.info?.remark, // 备注
          content: this.info?.mainText, // 规则正文

          classify: 'cate', // 后端需要的
        };

        let res = '';
        if (this.rowInfo?.id) {
          // 编辑
          options.id = this.rowInfo?.id;
          res = await editActicle(options);
        } else {
          // 新增
          res = await addActicle(options);
        }
        if (res?.code == 1) {
          this.$message.success(res.msg);
          this.$emit('handle-confirm', 'rules');
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 点击“取消”
    handleCancel() {
      this.$emit('handle-cancel');
    },
  },
};
