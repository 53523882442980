
// 组件
import SetHomeBanner from '../components/banner-table/BannerTable.vue'; //首页Banner设置
import SetCoreStrengths from './components/set-core-strengths/index.vue'; //核心优势设置
import SetOpenShop from './components/set-open-shop/index.vue'; //立即开店视频设置
import SetSide from './components/set-side/index.vue'; //侧边栏设置
import SetQrcode from './components/set-qrcode/index.vue'; //底部二维码设置
import SetHotKey from './components/set-hot-key/index.vue'; //热搜词
import OperationLog from '@/pages/back-stage/components/operation-Log/OperationLog.vue'; //操作日志
// 弹框
import AddBanner from '../components/AddBanner.vue';
// 组件
import EditorHotKey from './components/EditorHotKey';
// 变量
import { homeBackstage } from './js/homebackstage';
// API
import { getBannerList, getHotSearch } from '@/api/client-api/backstageapi.js';
export default {
  components: {
    SetHomeBanner,
    SetCoreStrengths,
    SetOpenShop,
    SetSide,
    SetQrcode,
    SetHotKey,
    AddBanner,
    EditorHotKey,
    OperationLog,
  },
  data() {
    return {
      mainList: homeBackstage,
      // 弹框
      hasEditorHotKey: false, //控制设置热搜词弹框
      hotTitle: '新增热搜词',

      hasAddBanner: false,
      rowInfo: {}, //表格选中数据
      bannerTitle: '新增首页banner',
      // 数据
      homeBannerData: [], //首页banner数据
      hotSearchData: [], //热搜词数据
    };
  },
  mounted() {
    this.getBannerList();
    this.getHotSearchData();
  },
  methods: {
    /**
     * 页面触发
     */
    // 获取热搜词列表
    async getHotSearchData() {
      const options = {};
      try {
        let res = await getHotSearch(options);
        if (res?.code == 1) {
          this.hotSearchData = res?.data?.data || [];
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 点击”新建“(热搜词)
    handleAddHotKey() {
      this.hotTitle = '新增热搜词';
      this.hasEditorHotKey = true;
    },
    // 点击热搜词取消按钮
    handleCancelHotKey() {
      this.rowInfo = {};
      this.hasEditorHotKey = false;
    },
    // 保存热搜词弹框确认按钮
    saveHotSearch() {
      this.handleCancelHotKey();
      this.getHotSearchData();
    },
    // 点击”编辑“热搜词
    handleEditorHotSearch(row) {
      this.rowInfo = row;
      this.hotTitle = '编辑热搜词';
      this.hasEditorHotKey = true;
    },
    // 首页banner设置
    async getBannerList() {
      try {
        const options = {
          filter: JSON.stringify({ classify: 'home_page' }),
        };
        let res = await getBannerList(options);
        if (res?.code == 1) {
          let list = JSON.parse(JSON.stringify(res?.data?.data || []));
          list.forEach((item) => {
            // 初始化
            item.switch = false;
            if (item.status == 1) {
              item.switch = true;
            }
          });
          this.homeBannerData = list;
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 点击“新建”(banner)
    handleAddBanner() {
      this.bannerTitle = '新增首页banner';
      this.hasAddBanner = true;
    },
    // 关闭banner图
    handleCancel() {
      this.rowInfo = {};
      this.hasAddBanner = false;
    },
    // 编辑新增轮播图确认事件
    handleConfBanner() {
      this.getBannerList();
      this.handleCancel();
    },
    // 点击“编辑”（banner）
    handleEditorBanner(row) {
      this.rowInfo = row;
      this.bannerTitle = '编辑首页banner';
      this.hasAddBanner = true;
    },
  },
};
