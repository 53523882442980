
export default {
  data() {
    return {
      highlight: this.$route.path, //高亮变量
      navList: [
        { name: '首页', link: '/backstage/home' },
        { name: '行业动态', link: '/backstage/industry' },
        { name: '学习中心', link: '/backstage/learn' },
        { name: '商家故事', link: '/backstage/story' },
        { name: '经营支持', link: '/backstage/businesssupport' },
        { name: 'seo设置', link: '/backstage/seobackstage' },
      ],
    };
  },
  methods: {
    /**
     * 页面触发事件
     */
    // 点击切换页面
    goUrl(url) {
      if (!url) {
        this.$message.warning('敬请期待');
        return;
      }
      this.$router.push(url);
      this.highlight = url;
    },
  },
};
