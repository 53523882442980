// 导入路由
import Vue from 'vue';
import VueRouter from 'vue-router';
// 前台
import frontdeskRoutes from '@/router/frontdesk.js';
// 后台
import Layout from '@/pages/Layout/index.vue';
import Login from '@/pages/back-stage/Login.vue'; //登录
import Modification from '@/pages/back-stage/Modification.vue'; //修改密码
import HomeBackstage from '@/pages/back-stage/home-backstage/index.vue'; //首页
import IndustryTrends from '@/pages/back-stage/industry-trends/index.vue'; //行业动态
import LearningCenter from '@/pages/back-stage/learning-center/index.vue'; //学习中心
import StoreStory from '@/pages/back-stage/store-story/index.vue'; //商家故事
import BusinessSupport from '@/pages/back-stage/business-support/index.vue'; //经营支持
import SeoBackstage from '@/pages/back-stage/seo-backstage/index.vue'; //经营支持

// 错误页面
import Error from '@/pages/error-page/error.vue';

Vue.use(VueRouter);
const routes = [
  ...frontdeskRoutes,
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/modification',
    name: 'Modification',
    component: Modification,
  },
  {
    path: '/backstage',
    component: Layout,
    redirect: '/backstage/home',
    children: [
      {
        path: 'home',
        name: 'backstageHome',
        component: HomeBackstage,
      },
      {
        path: 'industry',
        name: 'IndustryTrends',
        component: IndustryTrends,
      },
      {
        path: 'learn',
        name: 'LearningCenter',
        component: LearningCenter,
      },
      {
        path: 'story',
        name: 'StoreStoryBackstage',
        component: StoreStory,
      },
      {
        path: 'businesssupport',
        name: 'BusinessSupportBackstage',
        component: BusinessSupport,
      },
      {
        path: 'seobackstage',
        name: 'SeoBackstage',
        component: SeoBackstage,
      },
    ],
  },

  {
    path: '*',
    name: 'Not Found',
    component: Error,
  },
];
// 解决导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
export function createRouter() {
  return new VueRouter({
    mode: 'history',
    routes,
  });
}
