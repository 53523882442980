
import ImageShow from '@/pages/front-desk/components/ImageShow.vue'; // 展示图片/视频并预览
export default {
  name: 'ActicleBox',
  components: {
    ImageShow,
  },
  props: {
    // 当前盒子展示的文章内容
    details: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  methods: {},
};
