
// 方法
import { animation } from '@/utils/animation-num/animation_num.js';
export default {
  data() {
    return {
      listArr: this.coreAdvantagesList || [],
    };
  },
  props: {
    coreAdvantagesList: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    // 获取核心优势
    getDataList() {
      this.$getCoreAdvantages().then((res) => {
        if (res?.code == 1) {
          let list = res?.data || [];
          for (let i = 0; i < list.length; i++) {
            list[i].num = 0;
            animation(1900, 0, Number(list[i].number), (val) => {
              list[i].num = val.toFixed(0);
            });
          }
          this.listArr = list;
        }
      });
    },
  },
  beforeDestroy() {
    this.getDataList();
  },
};
