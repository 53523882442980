
export default {
  props: {
    webFeatures: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedNav: null, //被选中的一级标签
      num: 0, //用于列表的更新节点
    };
  },
  computed: {
    // 当前底部导航
    curWebFeatures() {
      let list = JSON.parse(JSON.stringify(this.webFeatures || [])) || [];
      list?.forEach((item) => {
        item.isShowLi = false;
      });
      return list || [];
    },
  },
  methods: {
    /**
     * 页面触发
     */
    handleNavFirst(navItem, list) {
      this.num++;
      if (this.selectedNav?.id == navItem?.id) {
        navItem.isShowLi = !navItem.isShowLi;
        return;
      }
      list?.forEach((item) => {
        item.isShowLi = false;
      });
      this.selectedNav = navItem;
      navItem.isShowLi = !navItem?.isShowLi || false;
    },
  },
};
