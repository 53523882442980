
// 变量
import { setHotKey } from './js/tablefield';
// api
import {
  getHotSearch,
  deketeHotSearch,
} from '@/api/client-api/backstageapi.js';
export default {
  data() {
    return {
      // tableData: [],
      // 弹框
      hasDoubleCheckDialog: false, //控制二次确认弹框
      rowInfo: {}, //记录选中的表格数据
      content: '您确定要删除吗？',
    };
  },
  props: {
    tableData: {
      type: Array,
      default:()=>[]
    }
  },
  computed: {
    // 当前表格的配置
    curTableFiled() {
      return setHotKey;
    },
  },
  methods: {
    /**
     * 页面触发
     */
    // 点击“编辑”
    handleEditor(row) {
      this.$emit('handle-editor',row)
    },
    // 点击“删除”
    handleDelete(row) {
      this.rowInfo = row;
      this.content = '您确定要删除吗？';
      this.hasDoubleCheckDialog = true;
    },
    // 二次确认弹框取消按钮
    handleCloseDia() {
      this.rowInfo = {};
      this.hasDoubleCheckDialog = false;
    },
    // 二次确认弹框确认按钮
    async handleConfigDia() {
      const options = {
        id: this.rowInfo.id || '',
      };
      try {
        let res = await deketeHotSearch(options);
        if (res?.code == 1) {
          this.$message.success(res.msg);
          this.handleCloseDia();
          this.$emit('up-data')
        }
      } catch (error) {
        console.error(error);
      }
    },

  },
  mounted() {
  },
};
