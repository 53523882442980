import { render, staticRenderFns } from "./index.vue?vue&type=template&id=4f1c4e25&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=4f1c4e25&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f1c4e25",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {XmUploadFileName: require('E:/ximu/nuxt-office3/components/xm-upload-file-name/XmUploadFileName.vue').default,DoubleCheckDialog: require('E:/ximu/nuxt-office3/components/double-check-dialog/DoubleCheckDialog.vue').default})
