
export default {
  name: 'SideNavigation',
  props: {
    // 侧边栏列表数据
    sideList: {
      type: Array,
      default: () => [],
    },
    // 侧标栏唯一标识
    currently: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      KeyIndex: '', //打开当前的导航
      isClickFirst: '', // 点击的一级分类
      hasColor:false
    };
  },
  watch: {
    currently(val) {
      if (val == '') {
        this.isClickFirst = '';
      }
    },
  },
  mounted() {
    this.hasColor = this.$route.fullPath.includes('/cardsplatRule')
  },
  methods: {
    // 点击一级分类
    getArticleFirst(id, title) {
      this.isClickFirst = id;
      this.$emit('go-first-level', id, title);
    },
    // 点击二级分类
    getArticleSecond(allid, id) {
      // this.currently = allid;
      this.$emit('go-second-level', allid, id);
    },
  },
};
