
export default {
  data() {
    return {
      list: [
        {
          title: '助力成长',
          description: '一对一带入门 岗位定期培训 季度团建培训',
          img: require('@/static/assets/images/grow.png'),
        },
        {
          title: '舒适办公',
          description: '业务流程清晰 人际关系简单 办公环境整洁',
          img: require('@/static/assets/images/office.png'),
        },
        {
          title: '福利丰富',
          description: '节庆专属礼品 丰厚绩效奖金 暖心生日礼物',
          img: require('@/static/assets/images/benefits.png'),
        },
        {
          title: '晋升有道',
          description: '定期评级机制 定期调薪机制 内部转岗机制',
          img: require('@/static/assets/images/promotion.png'),
        },
      ],
    };
  },
  methods: {
    /**
     * 页面触发
     */
    goUrl() {
      this.$router.push('/positionlist');
    },
  },
};
