
export default {
  data() {
    return {
      list: [
        {
          title: '平台规则',
          text: '关于细目商城的各种规则可以在此查看',
          img: 'test_upload_office-web_1706012691.png',
          link: '/platrule',
        },
        {
          title: '违规公示',
          text: '关于细目商城的各种违规公示可以在此查看',
          img: 'test_upload_office-web_1706013514.png',
          link: '',
        },
        {
          title: '意见征集',
          text: '关于细目商城的各种意见征集可以在此查看',
          img: 'test_upload_office-web_1706013646.png',
          link: '/zj',
        },
        {
          title: '知识产权维权中心',
          text: '关于细目商城的各种知识产权维权可以在此查看',
          img: 'test_upload_office-web_1706013682.png',
          link: '',
        },
      ],
    };
  },
  methods: {
    /**
     * 页面触发
     */
    goUrl(link) {
      if (!link) {
        this.$message.warning('敬请期待');
        return;
      }
      this.$router.push(link);
    },
  },
};
