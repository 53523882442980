
import { editCate, addCate } from '@/api/client-api/backstageapi.js';
export default {
  data() {
    return {
      typeTitle: this.curTypeInfo?.title || '',
    };
  },
  props: {
    // 当前标签的信息
    curTypeInfo: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    /**
     * 页面触发
     */
    // 点击“取消”
    handleCancel() {
      this.$emit('cancel');
    },
    // 点击“确认”
    async handleConfirm() {
      const options = {
        title: this.typeTitle,
      };
      try {
        let res;
        // 有id 编辑  没id新增
        if (this.curTypeInfo?.id) {
          options.id = this.curTypeInfo?.id;
          res = await editCate(options)
        } else {
          res = await addCate(options)
        }
        if (res?.code == 1) {
          this.$message.success(res.msg)
          this.$emit('up-type')
          this.handleCancel()
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
