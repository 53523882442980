
export default {
  data() {
    return {
      curActiveName: this.activeName, //选中的标签
      oldActiveName: this.activeName, //暂存选中
    };
  },
  props: {
    // 选中的标签
    activeName: {
      type: [String],
      default: '',
    },
    // 标签列表
    tabsList: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    activeName(n) {
      if (n) {
        this.curActiveName = n?.toString()
      }
    },
  },
  computed: {
    curTabsList() {
      return this.tabsList;
    },
  },
  methods: {
    /**
     * 页面触发
     */
    // 标签删除事件
    removeTabs(name) {
      let obj = this.curTabsList?.filter((item) => item.id == name)?.[0] || {};
      if (obj?.news_count > 0) {
        this.$message.warning('该分类下存在文章，不能删');
        return;
      }
      this.$emit('remove-tabs', obj);
    },
    // 切换标签
    handleClick() {
      if (this.oldActiveName == this.curActiveName) {
        return;
      }
      console.log(this.curActiveName);
      this.oldActiveName = this.curActiveName;
      this.$emit('tabs-click', this.curActiveName);
    },
  },
};
