
// 变量
import { tableConfig } from './js/tableConfig';
export default {
  data() {
    return {
    };
  },
  props: {
    // 当前表格数据
    cetTableList: {
      type: Array,
      default: () => [],
    },
  },
  watch: {},
  computed: {
    // 对应选中标签所用的列表
    curTableField() {
      return tableConfig;
    },
  },
};
