
// 组件
import EditorAdvantage from '../EditorAdvantage';
// 变量
import { setCoreStrengths } from './js/tablefield';
// api
import { getAdvantageList } from '@/api/client-api/backstageapi.js';
export default {
  components: {
    EditorAdvantage,
  },
  name: 'SetCoreStrengths',
  data() {
    return {
      tableData: [],
      // 弹框
      hasEditorAdvantage: false, //控制核心优势弹框
      rowInfo: {}, //选中的表格数据
    };
  },
  computed: {
    // 当前表格的配置
    curTableFiled() {
      return setCoreStrengths;
    },
  },
  methods: {
    /**
     * 页面触发
     */
    // 点击“编辑”
    handleEditor(row) {
      this.rowInfo = row;
      this.hasEditorAdvantage = true;
    },
    // 核心优势编辑弹框取消按钮
    handleCancel() {
      this.hasEditorAdvantage = false;
      this.rowInfo = {};
    },
    // 核心优势设置确认按钮
    handleConfirm() {
      this.getDataList();
      this.handleCancel();
    },
    // 获取列表
    async getDataList() {
      try {
        const optiosn = {};
        let res = await getAdvantageList(optiosn);
        if (res?.code == 1) {
          this.tableData = res.data.data || [];
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
  mounted() {
    this.getDataList();
  },
};
