export const industryBackstage = [
  {
    anchorpoint: 'setbusinesssupportbanner',
    title: '经营支持banner设置',
    cate_menu: 'manage_support',
    apiType: '1',
    searchlisttype: '4',
    tableType: '4',
  },
  {
    anchorpoint: 'setplatformrules',
    hasbtn: true,
    title: '平台规则设置',
  },
  {
    anchorpoint: 'setviolationpublicnotice',
    title: '违规公示时间设置',
    cate_menu: 'operateSupport',
    apiType:'3',
    searchlisttype: '4',
    tableType: '4',
  },
  {
    anchorpoint: 'setsolicitationviewsbanner',
    title: '意见征集banner设置',
    cate_menu: 'opinion_collect',
    apiType: '1',
    searchlisttype: '4',
    tableType: '4',
  },
  {
    anchorpoint: 'setsolicitationviews',
    hasbtn: true,
    title: '意见征集内容设置',
    cate_menu: 'opinion_collect',
    searchlisttype: '4',
    apiType: '7',
    tableType: '4',
  },
  {
    anchorpoint: 'solicitationviewsdata',
    title: '意见收集数据',
  },
  {
    anchorpoint: 'operationlog',
    title: '操作日志',
  },
];
