
import ImageShow from '@/pages/front-desk/components/ImageShow.vue';
export default {
  name: 'VideoBox',
  components: { ImageShow },
  props: {
    // 盒子需要的信息
    details: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showPreviewimg: false, // 预览弹窗状态
    };
  },
  methods: {
    // 预览图片回调
    showPreviewimgFunc() {
      this.showPreviewimg = true;
    },
    // 关闭预览回调
    previewCloseFunc() {
      this.showPreviewimg = false;
    },
  },
};
