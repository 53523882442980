
export default {
  data() {
    return {
      curList: this.list || [],
      num: 0,
    };
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    this.getStyle();
  },
  methods: {
    // 获取节点距离底部的位置
    getStyle() {
      let el = document.querySelectorAll('.change-box');
      for (let i = 0; i < el.length; i++) {
        if (this.num > 0) {
          el[i].style.marginBottom = (i - this.num) * 24 + 'px';
        } else {
          el[i].style.marginBottom = i * 24 + 'px';
        }
      }
    },
    // 点击首位或末尾移动
    changeLocation(index) {
      let el = document.getElementsByClassName('change-list');
      let computedStyles = getComputedStyle(el[0]);
      // 目前change-list 位于哪里
      let leftValue = computedStyles.getPropertyValue('left');
      // 获取宽度
      let widthValue = computedStyles.getPropertyValue('width');
      // 首先判断有几个数据
      const listNum = Math.abs(parseInt(widthValue) / 302);
      // 获取第几个位于可视区域的第一个(下标)
      const viewPortFirst = Math.abs(parseInt(leftValue) / 300);
      // 可以移动的条件 listNum > 4
      // 可以移动的位置
      // 首部：除了index == viewPortFirst == 0 的情况下不能移动，其余都可以
      // 尾部：index要大于等于3，不能超过 listNum 的最大值
      if (listNum > 4) {
        // 首部
        if (index == viewPortFirst && viewPortFirst != 0) {
          this.num = index - 1;
          el[0].style.left = this.num * -300 + 'px';
        }
        // 尾部
        if (index >= 3 && index != listNum - 1 && index - viewPortFirst == 3) {
          this.num = index - 2;
          el[0].style.left = this.num * -300 + 'px';
        }
      }
      this.getStyle();
    },
  },
};
