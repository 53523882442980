
// 变量
import { searchList } from './js/configinfo';
// api
import {
  getUser, //获取操作列表
  getConditionListSeo,
} from '@/api/client-api/backstageapi.js';
export default {
  name: 'OperationSearch',
  data() {
    return {
      // 搜索选中数据
      searchData: {
        seo_id: '', //修改方式
        field: '', //修改字段
        ope_id: '', //操作人
        time: [], //时间范围搜索
      },
      // 页面下拉（后端数据）
      pagesOptions: [],
      // 修改字段下拉列表（后端数据）
      modifyFieldOptions: {},
      // 操作人下拉列表（后端数据）
      operatorOptions: [],
    };
  },
  props: {
    // 当前选中的标签数据
    curMain: {
      type: Object,
      default: () => {},
    },
    // 列表总数/充当排序
  },
  computed: {
    // 根据不同的标签区分用哪几个搜索
    curSearchList() {
      return searchList;
    },
  },
  mounted() {
    this.getModifyingFields();
  },
  methods: {
    /**
     * 页面触发
     */
    // 筛选
    changeSearch() {
      let newObj = this.filterEmptyKey(this.searchData);
      // 特殊处理时间范围
      if (newObj?.time?.length > 0) {
        newObj.start_time = newObj?.time?.[0];
        newObj.end_time = newObj?.time?.[1];
        delete newObj.time;
      }
      this.$emit('chang-search', newObj);
    },
    // 获取操作日志下拉数据
    async getModifyingFields() {
      try {
        let res = await getConditionListSeo();
        if (res?.code == 1) {
          this.modifyFieldOptions = res?.data?.field || [];
          this.operatorOptions = res?.data?.operator || [];
          this.pagesOptions = res?.data?.page || [];
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 重置筛选
    reSearch() {
      this.searchData = {
        modifying_method: '', //修改方式
        old_sort: '', //排序序号
        modifying_fields: '', //修改字段
        operator_id: '', //操作人
        time: [], //时间范围搜索
      };
      this.changeSearch();
    },
  },
};
