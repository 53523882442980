
import SearchBox from '@/pages/front-desk/business-support/plat-rule/components/SearchBox.vue';
import JobInformation from './components/JobInformation';
import ApplyMessage from './components/ApplyMessage.vue';
import SubmitSuccess from './components/SubmitSuccess';
import Footer from '@/pages/front-desk/components/footer/Footer.vue'; //底部
export default {
  head() {
    return {
      title: '细目招聘_电商平台供应链公司-细目',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: '来细目，和我们启航一段梦想，为创造全球更美好生活而行。',
        },
        {
          hid: 'Keywords',
          name: 'Keywords',
          content: '细目招聘,电商平台供应链公司',
        },
      ],
    };
  },
  name: 'PositionDetail',
  components: {
    SearchBox,
    JobInformation,
    ApplyMessage,
    SubmitSuccess,
    Footer,
  },
  data() {
    return {
      twiceSureDialog: false, // 二次确认弹窗状态
    };
  },
  async asyncData(app) {
    // 拿到点击文章得到的ID
    let id = app.route.params.id;
    // 招聘信息
    let jobInfo = {};
    let options = {};
    if (id) {
      options.id = id;
    }
    try {
      let resJobInfo = await app.$getJobMessage(options);
      if (resJobInfo.code == 1) {
        jobInfo = (resJobInfo.data && resJobInfo.data[0]) || {};
      }
    } catch (error) {}

    return { jobInfo };
  },
  mounted() {
    // this.scrollToTop();
  },
  methods: {
    // 一次提交
    submitMsg() {
      this.twiceSureDialog = true;
    },
    // 二次确认提交
    adginSubmitMsg() {
      this.twiceSureDialog = false;
      this.$router.push('/positionlist');
    },
  },
};
