import { render, staticRenderFns } from "./index.vue?vue&type=template&id=1a9da125&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=1a9da125&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a9da125",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TopNavigation: require('E:/ximu/nuxt-office3/components/top-navigation/TopNavigation.vue').default,NavigationRight: require('E:/ximu/nuxt-office3/components/navigation-right/NavigationRight.vue').default})
