
// api
import { addBanner, editBanner } from '@/api/client-api/backstageapi.js';
export default {
  props: {
    // 列表数据长度
    dataLength: {
      type: Number,
      default: 0,
    },
    // 表格选中的数据
    rowInfo: {
      type: Object,
      default: () => {},
    },
    /**
     * 哪个轮播列表的添加/编辑banner
     * 后端需要的传值
     * 必传
     */
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      info: {
        images_pc: '', //pc端banner图
        images_mobile: '', //手机图
        link_url: '', //跳转链接
        sort: '', //跳转链接
        alt: '', //alt属性
      },
      hasSwitch: false, //发布开关
    };
  },
  computed: {
    //发布状态 0-未发布 1-已发布
    status() {
      if (this.hasSwitch) {
        return '1';
      } else {
        return '0';
      }
    },
    // 排序列表
    sortOptions() {
      let arr = [];
      for (let i = 0; i < this.dataLength?.length; i++) {
        const options = {
          label: i + 1,
          value: i + 1,
        };
        arr.push(options);
      }
      return arr;
    },
  },
  mounted() {
    // id存在是编辑弹窗
    if (this.rowInfo?.id) {
      this.getEditorData();
    }
  },
  methods: {
    /**
     * 页面触发
     */
    // 获取编辑信息
    getEditorData() {
      const options = {
        images_pc: '', //pc端banner图
        images_mobile: '', //手机图
        link_url: '', //跳转链接
        sort: '', //跳转链接
        alt: '', //alt属性
      };
      this.info = JSON.parse(JSON.stringify(this.rowInfo || options));
      this.hasSwitch = this.rowInfo.switch;
    },
    // 上传成功回调
    handleUploadFile(link, type) {
      // pc端
      if (type == 'pc') {
        this.info.images_pc = link;
      }
      // 移动端
      if (type == 'mobile') {
        this.info.images_mobile = link;
      }
    },

    // 点击“确定”
    async handleConfirm() {
      try {
        if (!this.info?.alt) {
          this.$message.error('还有必填没填');
          return;
        }
        // if (!this.info?.sort) {
        //   this.$message.error('还有必填没填');
        //   return;
        // }
        if (!this.info?.images_pc) {
          this.$message.error('还有必填没填');
          return;
        }
        if (!this.info?.images_mobile) {
          this.$message.error('还有必填没填');
          return;
        }
        const options = {
          sort: this.info.sort, //展示序号
          images_pc: this.info.images_pc, //pc图
          images_mobile: this.info.images_mobile, //移动端图
          classify: this.type, //向哪个轮播添加
          alt: this.info.alt, //alt属性
        };
        if (this.info?.link_url) {
          options.link_url = this.info.link_url; //跳转链接
        }
        // 是否发布
        if (this.hasSwitch) {
          options.status = '1';
        } else {
          options.status = '0';
        }
        let res = '';

        if (this.rowInfo?.id) {
          // 编辑
          options.id = this.rowInfo?.id;
          res = await editBanner(options);
        } else {
          // 新增
          res = await addBanner(options);
        }
        if (res?.code == 1) {
          this.$message.success(res.msg);
          this.$emit('handle-confirm', this.type, 'banner');
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 点击“取消”
    handleCancel() {
      this.$emit('handle-cancel');
    },
    // 数据重置
    reData() {
      this.info = {
        images_pc: '', //pc端banner图
        images_mobile: '', //手机图
        link_url: '', //跳转链接
        sort: '', //跳转链接
        status: '', //发布状态 0-未发布 1-已发布
        alt: '', //alt属性
      };
    },
  },
};
