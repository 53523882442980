
export default {
  data() {
    return {};
  },
  props: {
    info: {
      type: Object,
      default: null,
    },
  },
  methods: {
    // 设置a标签
    getLink(info) {
      const options = [
        { type: 'policy_dynamics', link: '/zc/' },
        { type: 'dynamic_domestic', link: '/gn/' },
        { type: 'dynamic_border', link: '/kj/' },
        { type: 'dynamic_company', link: '/newsdetails/' },
      ];
      for (let i = 0; i < options.length; i++) {
        if (info.classify == options[i].type) {
          return options[i].link + info.id;
        }
      }
      console.log(info, 'info');
    },
    // 获取标签className
    getClassName(type) {
      const options = {
        policy_dynamics: 'policy-news',
        dynamic_domestic: 'policy-plan',
        dynamic_border: 'official-information',
        dynamic_company: 'official-live',
        // 4: 'dizzy-style',
      };
      for (let i in options) {
        if (type == i) {
          return options[i];
        }
      }
    },
    // 获取标题className
    getTitleName(type) {
      const options = {
        policy_dynamics: 'policy-news-style',
        dynamic_domestic: 'policy-plan-style',
        dynamic_border: 'official-information-style',
        dynamic_company: 'official-live-style',
        // 4: 'dizzy-style-style',
      };
      for (let i in options) {
        if (type == i) {
          return options[i];
        }
      }
    },
  },
};
