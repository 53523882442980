
export default {
  name: 'JobInformation',
  components: {},
  data() {
    return {
    };
  },
  mounted() {
  },
  props: {
    jobInfo: {
      type: Object,
      default:{}
    }
  },
  methods: {
    getJobDetail() {
      let options = {
        id: this.jobId,
      };
      this.$getJobMessage(options).then((res) => {
        if (res.code == 1) {
          this.jobInfo = res.data[0];
        } else {
          this.$message.warning(res.msg);
        }
      });
    },
  },
};
