
export default {
  data() {
    return {
      list: [
        {
          title: '深圳总部',
          description: '深圳市龙华区龙胜恒博中心1505',
          img: require('@/static/assets/images/headquarters.png'),
        },
        {
          title: '深圳分部',
          description: '深圳市龙华区金銮国际商务大厦2期C405',
          img: require('@/static/assets/images/division.png'),
        },
      ],
    };
  },
};
