
// 分页器多语言标签配置
const PAGE_TOOLS_LABELS = {
  'zh-cn': {
    prev: '上一页',
    next: '下一页',
  },
  en: { prev: 'Back', next: 'Next' },
};
export default {
  name: 'pagTools',
  data() {
    return {
      curPages: this.pages, //当前页
      currentpage: '', //输入框的值
      allPage: '', //总页数
      currentpage1: 1,
    };
  },
  props: {
    // 使用的语言
    // 'zh-cn': 默认简体中文；'en': 英文
    lang: {
      type: String,
      default: 'zh-cn',
    },
    total: {
      type: [Number, String],
      default: 0,
    },
    pageSize: {
      type: [Number, String],
      default: 10,
    },
    pages: {
      type: [Number, String],
      default: 1,
    },
    // 是否显示分页器后面的快速跳转模块
    showJump: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    // 标签多语言配置
    labelConfig() {
      return PAGE_TOOLS_LABELS[this.lang || 'zh-cn'];
    },
  },
  watch: {
    currentpage: function (n) {
      if (!n) {
        return;
      }
      if (Number(n) < this.allPage) {
        this.currentpage = Number(n);
      } else {
        this.currentpage = this.allPage;
      }
    },
    pages: function (page) {
      if (page === 1) {
        this.currentpage = '';
      }
      this.curPages = page;
    },
  },
  methods: {
    // 总页数
    totalPageNum() {
      if (this.total == null || this.total == '') {
        return 0;
      } else {
        if (this.pageSize != 0 && this.total % this.pageSize == 0) {
          this.allPage = parseInt(this.total / this.pageSize);
          return parseInt(this.total / this.pageSize);
        }
        if (this.pageSize != 0 && this.total % this.pageSize != 0) {
          this.allPage = parseInt(this.total / this.pageSize) + 1;
          return parseInt(this.total / this.pageSize) + 1;
        }
      }
    },
    goPageBtn() {
      if (!this.currentpage) {
        this.$message({
          type: 'warning',
          message: '请选择要跳转的页码',
        });
        return;
      }
      this.curPages = Number(this.currentpage);
      this.currentpage1 = this.curPages;
      this.$emit('go-page-btn', this.curPages);
    },
    currentChange(n) {
      this.curPages = n;
      this.currentpage1 = this.curPages;
      this.$emit('current-change', n);
    },
    goPage(n) {
      if (this.total === 0) {
        return;
      }
      this.curPages += n;
      this.currentpage1 = this.curPages;
      this.$emit('go-page-num', this.curPages);
    },
    // 回到初始页面 加ref调用该方法
    changecurPage(n = 1) {
      this.curPages = n;
    },
  },
};
