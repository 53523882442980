/****   request.js   ****/
// 导入axios
import axios from 'axios';
import qs from 'qs';
// 使用element-ui Message做消息提醒
import { Message, Loading } from 'element-ui';
const service = axios.create({
  baseURL: '',
  // 超时时间 单位是ms，这里设置了3s的超时时间
  timeout: 30 * 1000,
  // withCredentials: true
});
let closeLoading = false;
let loading;

//当前正在请求的数量
let needLoadingRequestCount = 0;

// 显示loading
function showLoading(target, openLoading = false) {
  // 后面这个判断很重要，因为关闭时加了抖动，此时loading对象可能还存在，
  // 但needLoadingRequestCount已经变成0.避免这种情况下会重新创建个loading
  if (openLoading) {
    if (needLoadingRequestCount === 0 && !loading) {
      loading = Loading.service({
        lock: true,
        // text: 'Loading...',
        background: 'rgba(255, 255, 255, 0.5)',
        target: target || 'body',
      });
    }
    needLoadingRequestCount++;
  }
}
function _debounce(func, wait) {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(context, args);
    }, wait);
  };
}
// 防抖：将 300ms 间隔内的关闭 loading 便合并为一次。防止连续请求时， loading闪烁的问题。
let toHideLoading = _debounce(() => {
  if (loading) {
    loading.close();
    loading = null;
  }
}, 300);
// 隐藏loading
function hideLoading() {
  needLoadingRequestCount--;
  needLoadingRequestCount = Math.max(needLoadingRequestCount, 0); //做个保护
  if (needLoadingRequestCount === 0) {
    // 关闭loading
    toHideLoading();
  }
}

service.interceptors.request.use(
  (config) => {
    console.log(config,'config')
    config.metadata = { startTime: new Date() }; //记录请求开始时间,以便算出响应时间

    if (config.method == 'post') {
      if (!config.data) {
        config.data = {};
      }
      config.headers = Object.assign(config.headers || {}, {
        'Content-Type': 'application/x-www-form-urlencoded',
      });

      if (config.dataType === 'form-data') {
        // 处理上传文件到后端的表单数据格式
        // let formData = new FormData();
        // for (let key of Object.keys(config.data)) {
        //   formData.append(key, config.data[key]);
        // }
        // config.data = formData;
        config.data = config.data;
      } else {
        config.data = qs.stringify(config.data);
      }
    } else {
      config.data = JSON.stringify(config.data);
      if (!config.params) {
        config.params = {};
      }
      config.headers = Object.assign(config.headers || {}, {
        'Content-Type': 'application/json; charset=utf-8',
      });
    }
    //判断当前请求是否设置了不显示Loading
    if (!closeLoading) {
      // 如果有控制器
      if (config.controller && config.controller.openLoading) {
        showLoading(
          config.headers.loadingTarget,
          config.controller.openLoading
        );
        return config;
      }
      // 如果没控制器
      showLoading(config.headers.loadingTarget);
      return config;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    let resCode = response && response.data ? response.data.code : '';
    // 没登陆让它去登录页
    if (resCode == -101) {
      window.location.assign('/login');
      return;
    }
    if (resCode != 1) {
      let muteWarning = response?.config?.headers?.mute_msg_warning;
      if (response?.data?.msg && muteWarning != '1') {
        // 接口返回异常错误码（!= 0）时，统一在这里报错
        Message.warning(response.data.msg);
      }
    }
    // 不管成不成功关闭就完事了
    hideLoading();
    return response.data;
  },
  (error) => {
    hideLoading();
    let isTimeout = false;
    let errorMessage = '';
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          errorMessage = '错误请求';
          break;
        case 401:
          errorMessage = '未授权，请重新登录';
          break;
        case 403:
          errorMessage = '拒绝访问';
          break;
        case 404:
          errorMessage = '请求错误,未找到该资源';
          break;
        case 405:
          errorMessage = '请求方法未允许';
          break;
        case 408:
          errorMessage = '请求超时';
          break;
        case 500:
          errorMessage = '服务器端出错';
          break;
        case 501:
          errorMessage = '网络未实现';
          break;
        case 502:
          errorMessage = '网络错误';
          break;
        case 503:
          errorMessage = '服务不可用';
          break;
        case 504:
          errorMessage = '网络超时';
          break;
        case 505:
          errorMessage = 'http版本不支持该请求';
          break;
        default:
          errorMessage = `连接错误${error.response.status}`;
      }
    } else {
      errorMessage = '连接服务器失败';
    }
    let muteError = error?.response?.config?.headers?.mute_msg_error == 1;
    if (errorMessage && !muteError) {
      // 统一弹出报错提示语
      Message.error(errorMessage);
    }

    return Promise.reject(error?.response ? error.response : error);
  }
);

export default service;
