
export default {
  name: 'GiftPackBox',
  data() {
    return {
      giftUrl: 'test_upload_office-web_1706169416.png',
    };
  },
  computed: {},
  mounted() {},
  methods: {},
};
