
import { getRulesDetail } from '@/api/client-api/front_desk.js';
export default {
  name: 'RuleDetail',
  components: {},
  props: {
    // 规则文章ID
    ruleID: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      ruleDetailID: this.ruleID,
      ruleDetail: {},
    };
  },
  mounted() {
    this.getRulesDetail(); // 获取规则文章详情
  },
  watch: {
    ruleID(id) {
      this.ruleDetailID = id
      this.getRulesDetail();
    }
  },
  methods: {
    getRulesDetail() {
      if (this.ruleDetailID == ('' || -1)) return;
      let options = {
        id: this.ruleDetailID,
      };
      getRulesDetail(options).then((res) => {
        if (res?.code == 1) {
          console.log(res,'res123');
          this.ruleDetail = res?.data?.list;
        }
      });
    },
  },
};
