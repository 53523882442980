import request from '@/utils/request.js';
// 文章及视频列表
export function getArticleList(data) {
  return request({
    url: '/cn/getArticleList',
    method: 'get',
    params: data,
    // controller: {
    //   openLoading:true
    // }
  });
}
