
export default {
  name: 'CategoryTab',
  props: {
    /**
     * 要展示的是什么类型
     * learning_recommend 选品推荐
     * learning_operating 运营干货
     * learning_video 操作视频
     * store_story 商家故事
     */
    type: {
      type: String,
      default: '',
    },
    // 文章分类列表
    cateList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeCate: '', // 当前选中的文章类型
    };
  },
  computed: {
    acticleCateList() {
      let firstCate = [{ id: '', name: '全部', type: '' }];
      if (this.type == 'learning_recommend') firstCate[0].name = '全部品类';
      if (this.type == 'learning_operating') firstCate[0].name = '全部干货';
      if (this.type == 'learning_video') firstCate[0].name = '全部视频';
      return firstCate.concat(this.cateList);
    },
  },
  methods: {
    // 改变文章类型
    changeCategory(item) {
      this.activeCate = item.id;
      this.$emit('change-category', item.id, item.name);
    },
  },
};
