
import Vue from 'vue';
import request from '@/utils/request';
export default Vue.extend({
  name: 'BasicEditor',
  props: {
    useStatus: {
      type: String,
      default: 'add',
    },
    showDetail: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      onChange: () => {},
      bulletinContent: '',
      editor: null, // 编辑器实例
      // 修改菜单/工具栏的配置
      toolbarConfig: {
        excludeKeys: [
          'insertVideo', // 删除视频
          // 'uploadVideo',
          // 'group-video',
          'emotion', // 表情
          'insertImage', // 删除网络图片上传
          // 'insertLink',// 删除链接
          // 'insertTable', // 删除表格
          // 'codeBlock',// 删除代码块
        ],
      },
      mode: 'default', // 'default' or 'simple'
    };
  },
  computed: {
    // 修改编辑器的配置
    editorConfig() {
      let that = this;
      let settingEditor = {
        placeholder: '请输入',
        autoFocus: false,
        // readOnly: true,
        // 所有的菜单配置，都要在 MENU_CONF 属性下
        MENU_CONF: {
          uploadImage: {
            customUpload: this.uploadImg,
            maxFileSize: 20 * 1024 * 1024, // 5M,单个文件的最大体积限制
            allowedFileTypes: ['.jpg', '.jpeg', '.png'], // 选择文件时的类型限制
            server: '/xmadmin/Ajax/uploadFile',
            // 自定义上传参数，例如传递验证的 token 等。参数会被添加到 formData 中，一起上传到服务端
            // meta: {
            //   is_not_material: 1,
            // },
            // headers: {
            //   token: localStorage.getItem('token3'),
            // },
            fieldName: 'file',
          },
          uploadVideo: {
            customUpload: this.uploadImg,
            allowedFileTypes: ['.mp4'], // 选择文件时的类型限制
          },
        },
      };
      return settingEditor;
    },
  },
  mounted() {
    this.onChange = this._debounce(this.onChangeEditor, 100);

    this.$nextTick(() => {
      if (this.editor) {
        this.editor.on('fullScreen', () => {
          this.$emit('edit-change-full');
        });

        this.editor.on('unFullScreen', () => {
          this.$emit('edit-change-unfull');
        });
      }
    });
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
      if (this.useStatus == 'edit' && this.showDetail) {
        this.editor.setHtml(this.showDetail);
      }
    },
    onChangeEditor(editor) {
      this.$emit('edit-change', editor.getHtml());
    },
    // 清除wangeditor内的所有内容
    clearEditor() {
      this.editor.clear();
    },
    // 自定义上传
    uploadImg(file, insertFn) {
      console.log(file, '什么鬼东西');
      // 限制大小不能超过5M
      let fileType = file?.type?.split('/')[0];
      if (fileType == 'image' && file.size > 5 * 1024 * 1025) {
        this.$message({
          type: 'warning',
          message: '图片最大不能超过5M',
        });
        return false;
      }
      if (fileType == 'video' && file.size > 500 * 1024 * 1025) {
        this.$message({
          type: 'warning',
          message: '视频最大不能超过500M',
        });
        return false;
      }

      // 限制.png, .jpeg, .jpg
      let acceptFile = '.png, .jpeg, .jpg, .mp4';
      let fileNameLength = file.name.split('.').length;
      let limitType = acceptFile.replace(/ /g, '').replace(/\./g, '');
      if (limitType.includes(file.name.split('.')[fileNameLength - 1])) {
      } else {
        this.$message.warning('请上传' + limitType + '格式文件');
        return false;
      }

      const formData = new FormData();
      formData.append('file', file); // fixme
      const reqData = {
        url: '/xmadmin/Ajax/uploadFile', // fixme
        method: 'post',
        dataType: 'form-data',
        data: formData,
      };
      request(reqData)
        .then((res) => {
          if (res?.code == 1) {
            insertFn('https://office.static.ximu.cn/' + res?.data.link);
          }
        })
        .catch((err) => {
          // this.$message.warning('上传失败');
          console.error(err);
        });
    },
  },
});
