
// 组件
import BasicEditor from '@/pages/back-stage/components/BasicEditor';
// api
import { addOpinion, editOpinion } from '@/api/client-api/backstageapi.js';
export default {
  name: 'AddOpinionsCollect',
  components: { BasicEditor },
  props: {
    // 表格选中的数据
    rowInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      info: {
        cover: '', // 意见封面
        title: '', // 意见标题
        publishTime: '', // 发布时间
        mainText: '', // 文章正文
        cycle: [], // 征集周期
      },
      editorStatus: 'add', // 富文本状态 add/edit
      acticleKey: 0, // 富文本获取数据需要
    };
  },
  mounted() {
    // id存在,编辑弹窗
    if (this.rowInfo?.id) {
      this.editorStatus = 'edit';
      this.getActicleDeatil(); // 获取文章详情
    }
  },
  methods: {
    // 富文本内容改变
    editorChange(val) {
      this.info.mainText = val;
    },
    /**
     * 页面触发
     */
    // 获取文章详情
    async getActicleDeatil() {
      const options = {
        cover: '', // 意见封面
        title: '', // 意见标题
        publishTime: '', // 发布时间
        mainText: '', // 文章正文
        cycle: [], // 征集周期
      };
      let cycle = [
        this.rowInfo?.start_time + ' 00:00:00',
        this.rowInfo?.end_time + ' 23:59:59',
      ];
      this.info = JSON.parse(JSON.stringify(this.rowInfo || options));
      this.info.cover = this.rowInfo?.cover || ''; // 封面
      this.$set(this.info, 'publishTime', this.rowInfo?.publish_time || ''); // 发布时间
      this.$set(this.info, 'mainText', this.rowInfo?.content || ''); // 文章详情
      this.$set(this.info, 'cycle', cycle || ''); // 征集周期

      this.acticleKey++;
    },
    // 上传成功回调
    handleUploadFile(link) {
      this.info.cover = link;
    },
    // 点击“确定”
    async handleConfirm() {
      try {
        let mainText = this.info?.mainText
          ?.replace(/<(?!img)(?!video)[^>]+>/g, '')
          ?.trim();
        // 共有
        if (
          !this.info?.cover ||
          !this.info?.title ||
          !this.info?.cycle ||
          !this.info?.publishTime ||
          !mainText
        ) {
          this.$message.error('还有必填没填');
          return;
        }
        const options = {
          cover: this.info?.cover, // 意见封面
          title: this.info?.title, // 意见标题
          start_time: this.info?.cycle[0], // 征集开始时间
          end_time: this.info?.cycle[1], // 征集结束时间
          publish_time: this.info?.publishTime, // 发布时间
          content: this.info?.mainText, // 文章正文
        };

        let res = '';
        if (this.rowInfo?.id) {
          // 编辑
          options.id = this.rowInfo?.id;
          res = await editOpinion(options);
        } else {
          // 新增
          res = await addOpinion(options);
        }
        if (res?.code == 1) {
          this.$message.success(res.msg);
          this.$emit('handle-confirm', 'opinion');
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 点击“取消”
    handleCancel() {
      this.$emit('handle-cancel');
    },
  },
};
