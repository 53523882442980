
export default {
  name: 'XimuDialog',
  props: {
    content: {
      type: String,
      default: '您确定要删除吗？',
    },
    deleteDialogVisible: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'red',
    },
    // 确认按钮文字
    sureBtnFont: {
      type: String,
      default: '确认',
    },
  },
  data() {
    return {};
  },
  methods: {
    closeDeletedig() {
      // this.deleteDialogVisible = false;
      this.$emit('close-deletedig'); // 父组件绑定的事件
    },
    configDelete() {
      // this.deleteDialogVisible = false;
      this.$emit('config-deletedig'); // 父组件绑定的事件
    },
  },
};
