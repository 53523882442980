
export default {
  name: 'SearchBox',
  props: {
    title: {
      type: String,
      default: '',
    },
    titleTip: {
      type: String,
      default: '',
    },
    searchPlaceholder: {
      type: String,
      default: '请输入',
    },
  },
  data() {
    return {
      searchWord: '', // 搜索的内容
      // searchOldWord: '',
    };
  },
  mounted() {},
  methods: {
    // 点击/回车搜索
    search() {
      // if (this.searchWord == this.searchOldWord) {
      //   return;
      // }
      if (this.searchWord !== '') {
        this.$emit('search-word', this.searchWord);
        // this.searchOldWord = this.searchWord;
      }
      this.$nextTick(() => {
        this.searchWord = '';
      });
    },
  },
};
