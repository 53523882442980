
// api
import { getListData, editBottomQr } from '@/api/client-api/backstageapi.js';
export default {
  data() {
    return {
      // 限制可上传的类型
      dataInfo: {},

      // 弹框
      hasDoubleCheckDialog: false,
      content: '您确定要删除吗？',
    };
  },
  mounted() {
    this.getSetData();
  },
  methods: {
    /**
     * 页面触发
     */
    // 上传成功回调
    async uploadFile(link,upType, type) {
      console.log(link, 'link');
      try {
        const options = {
          bottom_qr: link,
        };
        let res = await editBottomQr(options);
        if (res?.code == 1) {
          if (type != 'noTips') {
            this.$message.success(res.msg);
          }
          this.dataInfo.value = link;
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 点击”删除“
    handleDelete() {
      this.hasDoubleCheckDialog = true;
    },
    // 二次确认弹框确认按钮
    async handleConfigDia() {
      try {
        const options = {
          bottom_qr: '',
        };
        let res = await editBottomQr(options);
        if (res?.code == 1) {
          this.$message.success(res.msg);
          this.handleCloseDia();
          this.dataInfo.value = '';
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 二次确认弹框取消按钮
    handleCloseDia() {
      this.hasDoubleCheckDialog = false;
    },
    // 获取立即开店的视频设置
    async getSetData() {
      try {
        const options = {
          name: 'bottom_qr',
        };
        let res = await getListData(options);
        if (res.code == 1) {
          this.dataInfo = res.data?.[0] || {};
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
