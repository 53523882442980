
import {
  addBanner,
  editBanner,
  getBannerList,
} from '@/api/client-api/backstageapi.js';
export default {
  data() {
    return {
      dataInfo: {
        images_mobile: '',
        images_pc: '',
        alt: '',
      }, //数据信息
      oldData: {}, //保存旧数据
      isdisabled: true, //alt属性是否禁止编辑
    };
  },
  props: {
    /**
     * 这里用于区分对哪个banner图设置进行操作
     * 对应官网系统类别 home_page：首页 ，policy_dynamics: 政策动态，dynamic_domestic：国内新闻 ，dynamic_border：跨境资讯，dynamic_company：公司新闻，learning_recommend：选品推荐，learning_operating：运营干货，learning_video：操作视频
     * 例子：pages\back-stage\industry-trends\index.vue
     */
    bannerType: {
      type: String,
      default: '',
    },
  },
  mounted() {
    this.getBannerInfo();
  },
  methods: {
    /**
     * 页面触发
     */
    // 获取banner数据
    async getBannerInfo() {
      const options = {
        filter: JSON.stringify({ classify: this.bannerType }),
      };
      try {
        let res = await getBannerList(options);
        if (res?.code == 1) {
          this.dataInfo = res?.data?.data?.[0] || {};
          this.oldData = JSON.parse(JSON.stringify(this.dataInfo || {}));
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 上传(编辑图片)
    async uploadFile(data, upType, type) {
      const options = {
        classify: this.bannerType,
        alt: this.dataInfo.alt, //alt属性
        sort: '0', //排序
        status: '1', //显示状态0:未发布，1：已发布)
      };
      if (upType == 'pc') {
        options.images_pc = data;
        options.images_mobile = this.dataInfo?.images_mobile || '';
      }
      if (upType == 'mobile') {
        options.images_pc = this.dataInfo?.images_pc || '';
        options.images_mobile = data;
      }
      let res;
      // 回显不走请求
      if (type != 'noTips') {
        if (this.dataInfo?.id) {
          options.id = this.dataInfo?.id;
        }
        res = await this.triggerApi(options);
        if (res?.code == 1) {
          this.$message.success(res.msg);
        }
      }

      // pc端
      if (upType == 'pc') {
        this.dataInfo.images_pc = data;
      }
      // 移动端
      if (upType == 'mobile') {
        this.dataInfo.images_mobile = data;
      }
    },
    // 取消alt属性编辑状态
    handleCancelEditor() {
      // 取消编辑状态后要回到原来的数据
      this.isdisabled = true;
      this.dataInfo.alt = this.oldData?.alt;
    },
    // 开启编辑alt属性后点击“完成”
    async handleEditorSuccess() {
      const options = {
        images_mobile: this.dataInfo?.images_mobile || '', //移动端banner图
        images_pc: this.dataInfo?.images_pc || '', //pc端banner图
        alt: this.dataInfo?.alt || '', //alt属性
        classify: this.bannerType,
        sort: '0', //排序
        status: '1', //显示状态0:未发布，1：已发布)
      };
      let res;
      if (this.dataInfo?.id) {
        options.id = this.dataInfo?.id;
      }
      res = await this.triggerApi(options);
      if (res?.code == 1) {
        this.$message.success(res.msg);
        this.isdisabled = true;
      }
    },
    // 接口请求
    async triggerApi(options) {
      try {
        let res;
        if (this.dataInfo?.id) {
          res = await editBanner(options);
        } else {
          res = await addBanner(options);
          // 新增的时候要更新一次数据，不然会出现没有id会继续新增的情况
          if (res?.code == 1) {
            this.getBannerInfo();
          }
        }
        return res;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
