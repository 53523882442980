
// api
import { editSettingSeo } from '@/api/client-api/backstageapi.js';
export default {
  data() {
    return {
      info: JSON.parse(JSON.stringify(this.rowInfo || {})) || {},
    };
  },
  props: {
    // 获取到选中的表格数据
    rowInfo: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {},
  methods: {
    /**
     * 页面触发
     */
    // 点击“发布”
    async handleConfirm() {
      const obj = ['title', 'description', 'keyword'];
      for (let i of obj) {
        if (!this.info[i]) {
          this.$message.error('有必填项没填');
          return;
        }
      }
      const options = {
        seo_id: this.rowInfo.seo_id,
        title:this.info.title,
        description:this.info.description,
        keyword:this.info.keyword,
      };
      try {
        let res = await editSettingSeo(options);
        if (res?.code == 1) {
          this.$message.success(res.msg);
          this.$emit('handle-release');
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 点击取消
    handleCancel() {
      this.$emit('handle-cancel');
    },
  },
};
