
export default {
  data() {
    return {
      curInfo: this.detailsInfo || {},
    };
  },
  props: {
    detailsInfo: {
      type: Object,
      default: () => {},
    },
  },
};
