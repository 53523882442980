
import { tableConfig } from '../js/table_config';
export default {
  props: {
    // 表格数据
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
    };
  },
  computed: {
    // 当前采用的列表配置
    curTableFiled() {
      return tableConfig;
    },
    // 当前用的列表
    curTable() {
      return JSON.parse(JSON.stringify(this.tableData || [])) || []
    },
    // 存储旧列表
    oldTable() {
      return JSON.parse(JSON.stringify(this.tableData || [])) || []
    }
  },
  methods: {
    /**
     * 页面触发
     */
    // 点击“编辑”
    handleEditorNews(row) {
      this.$emit('edit-rules', row);
    },
    // 点击“删除”
    handleDeleteNews(row) {
      this.$emit('delete-rules', row);
    },
    // 修改状态
    changeSwitch(row) {
      let info = this.oldTable?.filter(item => item.id == row.id)?.[0] || {}
      row.home_page_status = info.home_page_status
      this.$emit('change-switch',row)
      console.log(row,'row');
    },
  },
};
