
// api
import { getConfig } from '@/api/client-api/front_desk.js';
// 组件
import PhonesMiddle from './PhonesMiddle.vue';
export default {
  components: { PhonesMiddle },
  name: 'Footer',
  data() {
    return {
      webFeatures: [
        {
          id: 83,
          title: '招商合作',
          child: [
            {
              id: 87,
              title: '供货商入驻',
              link_url: '/register/supplier',
              target: '_block',
              isOutbound: true, //是否出站
            },
            {
              id: 88,
              title: '零售商入驻',
              link_url: '/register/shop',
              target: '_block',
              isOutbound: true, //是否出站
            },
          ],
        },
        {
          id: 86,
          title: '行业动态',
          child: [
            // {
            //   title: '政策动态',
            //   link_url: '/zc',
            //   target: '_self',
            // },
            {
              title: '国内新闻',
              link_url: '/gn',
              target: '_self',
            },
            {
              title: '跨境资讯',
              link_url: '/kj',
              target: '_self',
            },
            {
              title: '公司新闻',
              link_url: '/news',
              target: '_self',
            },
          ],
        },
        {
          id: 100,
          title: '学习中心',
          child: [
            {
              title: '选品推荐',
              link_url: '/xp',
              target: '_self',
            },
            {
              title: '运营干货',
              link_url: '/yy',
              target: '_self',
            },
            {
              title: '操作视频',
              link_url: '/sp',
              target: '_self',
            },
          ],
        },
        {
          id: 101,
          title: '经营支持',
          child: [
            {
              title: '平台规则中心',
              link_url: '/platrule',
              target: '_self',
            },
            {
              title: '知识产权维权中心',
              link_url: '',
              target: '_self',
            },
          ],
        },
      ],
      linkList: [
        {
          title: '湘ICP备2023010376号-1',
          url: 'https://beian.miit.gov.cn/#/Integrated/index',
        },
        {
          title: '湘公网备案 43019002001936',
          url: 'https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=43019002001936',
        },
        { title: '增值电信业务经营许可证: 湘B2-20230578' },
        { title: 'Copyright © 2023-2033 ximu.cn 版权所有' },
        { title: '违法和不良信息举报电话：0755-27761552' },
      ],
      bottom_qr: {}, //底部二维码设置信息

      isShowType: true, //判断出现链接列表的方式
    };
  },
  props: {},
  mounted() {
    // this.getBottomNav();
    this.getConfig();
    this.$nextTick(() => {
      this.checkScreenWidth();
      window.addEventListener(
        'resize',
        this._throttle(this.checkScreenWidth, 150)
      );
    });
  },
  methods: {
    // 获取底部导航信息
    getBottomNav() {
      this.webFeatures = [
        {
          id: 83,
          title: '招商合作',
          child: [
            {
              id: 87,
              title: '供货商入驻',
              link_url: '/register/supplier',
              target: '_block',
              isOutbound: true, //是否出站
            },
            {
              id: 88,
              title: '零售商入驻',
              link_url: '/register/shop',
              target: '_block',
              isOutbound: true, //是否出站
            },
          ],
        },
        {
          id: 86,
          title: '行业动态',
          child: [
            // {
            //   title: '政策动态',
            //   link_url: '/zc',
            //   target: '_self',
            // },
            {
              title: '国内新闻',
              link_url: '/gn',
              target: '_self',
            },
            {
              title: '跨境资讯',
              link_url: '/kj',
              target: '_self',
            },
            {
              title: '公司新闻',
              link_url: '/news',
              target: '_self',
            },
          ],
        },
        {
          id: 100,
          title: '学习中心',
          child: [
            {
              title: '选品推荐',
              link_url: '/xp',
              target: '_self',
            },
            {
              title: '运营干货',
              link_url: '/yy',
              target: '_self',
            },
            {
              title: '操作视频',
              link_url: '/sp',
              target: '_self',
            },
          ],
        },
        {
          id: 101,
          title: '经营支持',
          child: [
            {
              title: '平台规则中心',
              link_url: '/platrule',
              target: '_self',
            },
            {
              title: '知识产权维权中心',
              link_url: '',
              target: '_self',
            },
          ],
        },
      ];
    },
    // 获取二维码信息
    async getConfig() {
      try {
        let res = await getConfig();
        if (res?.code == 1) {
          this.bottom_qr = res?.data?.bottom_qr?.[0] || {};
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 跳转
    goWebLink(item) {
      if (item.target == '_self') {
        this.$router.push(item.link_url);
      }
      if (item.target == '_blank') {
        this.jumpEnv(item.link_url);
      }
    },
    // 跳转法律所需页面
    goLaw(item) {
      if (item.url) {
        window.open(item.url);
      }
    },

    checkScreenWidth() {
      const screenWidth = window.innerWidth;
      if (screenWidth < 768) {
        this.isShowType = false;
      } else {
        this.isShowType = true;
      }
    },
  },
  beforeDestroy() {
    if (process.client) {
      window.removeEventListener('resize', this.checkScreenWidth);
    }
  },
};
