
export default {
  name: 'NeedMaterial',
  data() {
    return {
      // 资料信息
      infoList: [
        {
          id: 1,
          pic: require('@/static/assets/images/user_info.png'),
          font: '法定代表人姓名、联系方式、身份信息等',
        },
        {
          id: 2,
          pic: require('@/static/assets/images/social_code.png'),
          font: '统一社会信用代码、企业名称、详细地址、经营范围等',
        },
        {
          id: 3,
          pic: require('@/static/assets/images/account_info.png'),
          font: '店铺信息、收款账户信息',
        },
        {
          id: 4,
          pic: require('@/static/assets/images/brand_auth.png'),
          font: '品牌认证相关信息',
        },
      ],
    };
  },
  computed: {},
  mounted() {},
  methods: {},
};
