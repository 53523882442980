import request from '@/utils/request.js';
// 获取全局设置信息
export function getConfig(data) {
  return request({
    url: '/cn/getHomeInfo',
    method: 'get',
    params: data,
  });
}

// 获取文章列表
export function getActicleList(data) {
  return request({
    url: '/cn/getArticleList',
    method: 'get',
    params: data,
  });
}

// 获取规则列表
export function getRulesList(data) {
  return request({
    url: '/cn/getList',
    method: 'get',
    params: data,
  });
}
// 搜索规则
export function searchRules(data) {
  return request({
    url: '/cn/searchNews',
    method: 'get',
    params: data,
  });
}

// 获取规则详情
export function getRulesDetail(data) {
  return request({
    url: '/cn/getDetail',
    method: 'get',
    params: data,
  });
}

// 保存意见
export function addOpinion(data) {
  return request({
    url: '/cn/addOpinion',
    method: 'post',
    data: data,
  });
}

// 意见征集列表
export function opinionList(data) {
  return request({
    url: '/cn/opinionList',
    method: 'get',
    params: data,
  });
}

// 获取招聘信息列表
export function getJobList(data) {
  return request({
    url: '/cn/Recruitment/getList',
    method: 'get',
    params: data,
  });
}

// 搜索招聘信息、获取招聘详情
export function getJobMessage(data) {
  return request({
    url: '/cn/Recruitment/search',
    method: 'get',
    params: data,
  });
}

// 提交应聘者信息
export function submitUserMsg(data) {
  return request({
    url: '/cn/Recruitment/addRecruitmentApplicant',
    method: 'post',
    data: data,
  });
}
