
// 变量
import {
  setBannerField,
  recommendField,
  dryInfoField,
  actionVideoField,
  storeStroyField,
  setSolicitationViewsField,
} from './js/tablefield';
// api
import { deleteNews, editNews } from '@/api/client-api/backstageapi.js';
export default {
  data() {
    return {
      oldData: this.tableData,
      // 弹框
      hasDoubleCheckDialog: false, //控制二次确认弹框
      rowInfo: {}, //记录选中的表格数据
      content: '您确定要删除吗？',
      hasPreview: false, //控制预览弹框
      previewLink: '', //选中预览的图片
    };
  },
  props: {
    // 表格数据
    // 例子：pages\back-stage\industry-trends\index.vue
    tableData: {
      type: Array,
      default: () => [],
    },
    /**
     * 区分什么类型的文章列表
     * 新闻分类 policy_dynamics: 政策动态，dynamic_domestic：国内新闻 ，dynamic_border：跨境资讯，dynamic_company：公司新闻，learning_center：学习中心，learning_recommend：选品推荐，learning_operating：运营干货
     */
    type: {
      type: String,
      default: '',
    },
    // 分页数据
    pageInfo: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    tableData(n) {
      if (n) {
        this.oldData = JSON.parse(JSON.stringify(n));
      }
    },
  },
  computed: {
    // 当前表格的配置
    curTableFiled() {
      if (this.type == 'learning_recommend') {
        return recommendField;
      }
      if (this.type == 'learning_operating') {
        return dryInfoField;
      }
      if (this.type == 'learning_video') {
        return actionVideoField;
      }
      if (this.type == 'store_story') {
        return storeStroyField;
      }
      return setBannerField;
    },
    // 当前的表格信息
    curTableData() {
      return this.tableData;
    },
    // 当前分页数据
    curPageInfo() {
      return this.pageInfo;
    },
  },
  methods: {
    /**
     * 页面触发
     */
    // 分页切换
    goPages(val) {
      this.$emit('go-pages', val, this.type);
    },
    // 点击图片预览
    handlePreview(link) {
      this.previewLink = link;
      this.hasPreview = true;
    },
    // 关闭预览弹框
    handleClosePreview() {
      this.hasPreview = false;
      this.previewLink = '';
    },
    // 点击“编辑”
    handleEditor(row) {
      this.$emit('editor-acticle', row, this.type);
    },
    // 点击“删除”
    handleDelete(row) {
      this.rowInfo = row;
      this.hasDoubleCheckDialog = true;
    },
    // 二次确认弹框取消按钮
    handleCloseDia() {
      this.rowInfo = {};
      this.hasDoubleCheckDialog = false;
      this.content = '您确定要删除吗？';
    },
    // 二次确认弹框确认按钮
    async handleConfigDia() {
      const options = {
        id: this.rowInfo.id,
      };
      try {
        let res = await deleteNews(options);
        if (res?.code == 1) {
          this.$message.success(res.msg);
          this.handleCloseDia();
          this.$emit('delete-data', this.type);
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 修改发布状态
    async changeSwitch(row, isSwitch, type) {
      // 改变显示状态前，先锁定状态值
      for (let i = 0; i < this.oldData.length; i++) {
        if (row.id == this.oldData[i].id) {
          row[type] = this.oldData[i][type];
          break;
        }
      }
      // 进行正常的修改状态
      const options = {
        id: row.id,
        classify: this.type,
      };
      if (row[type] == '1') {
        // 目前发布 -> 不发布
        options[type] = '0';
      } else if (row[type] == '0') {
        // 目前不发布 -> 发布
        options[type] = '1';
      }
      try {
        let res = await editNews(options);
        if (res?.code == 1) {
          this.$message.success(res.msg);
          this.$emit('up-data', this.type, 'acticle');
        }
      } catch (error) {
        console.error(error);
      }
      console.log(row, isSwitch, type, 'row, isSwitch, type', this.oldData);
    },
  },
  mounted() {},
};
