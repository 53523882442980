
// 组件
import OperationSearch from './OperationSearch'; //搜索组件
import OperationTable from './OperationTable'; //表格数据
// api
import {
  operationLogRecordBanner, //banner相关日志列表
  operationLogRecordConfig, //系统设置相关日志列表
  operationLogRecordHotSearch, //热搜词相关日志列表
  operationLogRecordCoreAdvantages, //核心优势相关日志列表
  operationLogRecordNews, //新闻相关日志列表
  operationLogRecordCate, //规则分类
  operationLogRecordOpinion,
  operationLogCateList,
} from '@/api/client-api/backstageapi.js';
//  变量
import { apiType } from './js/configinfo';
export default {
  components: {
    OperationSearch,
    OperationTable,
  },
  data() {
    return {
      // --------
      // 注册api
      operationLogRecordBanner,
      operationLogRecordConfig,
      operationLogRecordHotSearch,
      operationLogRecordCoreAdvantages,
      operationLogRecordNews,
      operationLogRecordCate,
      operationLogRecordOpinion,
      operationLogCateList,
      // --------
      apiType,
      activeName:
        this.mainList[0]?.cate_menu + this.mainList[0]?.anchorpoint || '', //激活的标签名(初始拿第一个)
      canClickTab: false, // 初始不允许点击切换tab
      apiActive: this.mainList[0]?.cate_menu || '', //激活哪一个API传参
      curMain: this.mainList?.[0] || {}, //选中的标签
      // 表格
      cetTableList: [], //表格数据
      page_num: this.pages, //页码
      page_size: this.pagesize, //每页数量
      total: 0,
      // 搜索
      searchData: {},
    };
  },
  props: {
    // 标签列表用于切换标签的列表
    mainList: {
      type: Array,
      default: () => [],
    },
    // 页码
    pages: {
      type: Number,
      default: 1,
    },
    // 条数
    pagesize: {
      type: Number,
      default: 10,
    },
  },
  computed: {
    // 当前所使用的列表
    curMainList() {
      // return this.mainList;
      return this.mainList.filter((item) => {
        return (
          item.anchorpoint !== 'operationlog' &&
          item.anchorpoint !== 'setplatformrules' &&
          item.anchorpoint !== 'solicitationviewsdata'
        );
      });
    },
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    // tab切换前
    beforeLeave() {
      this.canClickTab = true; // 说明切换了tab,需要更新数据
    },
    // 切换tab
    handleClickTab(item) {
      if (!this.canClickTab) return; // 没切换tab
      this.canClickTab = false; // 等下一次切换
      this.curMain = this.curMainList?.[item.index];
      this.apiActive = this.curMain?.cate_menu;
      if (this.$refs?.operationsearch) {
        this.$refs?.operationsearch?.reSearch();
      }
      console.log('来了吗？');
    },
    /**
     * 页面触发
     */
    // 搜索
    changSearch(data) {
      this.searchData = data;
      this.rePage();
    },
    // 点击分页
    goPage(val) {
      this.page_num = val;
      this.getDataList();
    },
    // 重置页码
    rePage() {
      this.page_num = 1;
      this.getDataList();
    },
    // 点击标签事件
    handleClickLab(item) {
      if (item.cate_menu + item.anchorpoint == this.activeName) return;
      this.activeName = item.cate_menu + item.anchorpoint;
      this.apiActive = item.cate_menu;
      this.curMain = item;
      if (this.$refs?.operationsearch) {
        this.$refs?.operationsearch?.reSearch();
      }
    },
    // 获取列表数据
    async getDataList() {
      // 初始化
      this.cetTableList = [];
      this.total = 0;
      const options = {
        cate_menu: this.apiActive,
        page_size: this.page_size,
        page_num: this.page_num,
        ...this.searchData,
      };
      try {
        let res =
          (await this?.[apiType?.[this.curMain?.apiType]](options)) || {};
        if (res?.code == 1) {
          this.cetTableList = res?.data?.data || [];
          this.total = res?.data?.count || 0;
        } else {
          this.cetTableList = [];
          this.total = 0;
        }
      } catch (error) {
        console.error(error);
        this.cetTableList = [];
        this.total = 0;
      }
    },
  },
};
