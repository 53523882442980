import { render, staticRenderFns } from "./PositionDetail.vue?vue&type=template&id=4ba9ca43&scoped=true"
import script from "./PositionDetail.vue?vue&type=script&lang=js"
export * from "./PositionDetail.vue?vue&type=script&lang=js"
import style0 from "./PositionDetail.vue?vue&type=style&index=0&id=4ba9ca43&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ba9ca43",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TopNavigation: require('E:/ximu/nuxt-office3/components/top-navigation/TopNavigation.vue').default,NavigationRight: require('E:/ximu/nuxt-office3/components/navigation-right/NavigationRight.vue').default})
