// 前台
import HomePage from '@/pages/front-desk/home-page/index.vue'; //首页

// ---------------------------------------------------------------------------------------------------------------------------
import SupplierEntry from '@/pages/front-desk/entry-channel/supplier-entry'; // 入驻通道--供货商入驻
import ShopEntry from '@/pages/front-desk/entry-channel/shop-entry'; // 入驻通道--零售商入驻

// ---------------------------------------------------------------------------------------------------------------------------
import Recommend from '@/pages/front-desk/learning-center/recommend'; // 学习中心--选品推荐
import DryInfo from '@/pages/front-desk/learning-center/dry-info'; // 学习中心--运营干货
import OperateVideo from '@/pages/front-desk/learning-center/operate-video'; // 学习中心--操作视频
import ActicleDetails from '@/pages/front-desk/learning-center/acticle-details'; // 学习中心--文章详情(商家故事详情也是)

// -----------------------------------------------------------------------------------------------------------------------------
import StoreStory from '@/pages/front-desk/store-story/index.vue'; // 商家故事

// -----------------------------------------------------------------------------------------------------------------------------
import BusinessSupport from '@/pages/front-desk/business-support/index.vue'; //经营支持--综合页面
import PlatRule from '@/pages/front-desk/business-support/plat-rule/PlatRule.vue'; //经营支持-平台规则

import SolicitationViews from '@/pages/front-desk/business-support/solicitation-views/index.vue'; //经营支持-意见征集
import SolicitationViewsDetails from '@/pages/front-desk/business-support/solicitation-views/SolicitationViewsDetails.vue'; //经营支持-意见征集详情

// -----------------------------------------------------------------------------------------------------------------------------
import PolicyNews from '@/pages/front-desk/industry-trends/policy-news/index.vue'; //行业动态-政策动态
import PolicyNewsDetails from '@/pages/front-desk/industry-trends/policy-news/PolicyNewsDetails.vue'; //行业动态-政策动态详情

import DomesticNews from '@/pages/front-desk/industry-trends/domestic-news/index.vue'; //行业动态-国内新闻
import DomesticNewsDetails from '@/pages/front-desk/industry-trends/domestic-news/DomesticNewsDetails.vue'; //行业动态-国内新闻详情

import WorldNews from '@/pages/front-desk/industry-trends/world-news/index.vue'; //行业动态-跨境资讯
import WorldNewsDetails from '@/pages/front-desk/industry-trends/world-news/WorldNewsDetails.vue'; //行业动态-跨境资讯详情

import CompanyNews from '@/pages/front-desk/industry-trends/company-news/index.vue'; //行业动态-公司新闻
import CompanyNewsDetails from '@/pages/front-desk/industry-trends/company-news/CompanyNewsDetails.vue'; //行业动态-公司新闻详情

// ---------------------------------------------------------------------------------------------------------------------------------
import JoinUs from '@/pages/front-desk/about-us/join-us/index.vue'; //关于我们-加入我们
import CompanyProfile from '@/pages/front-desk/about-us/company-profile/index.vue'; //关于我们-企业简介
// 招聘相关
import PositionList from '@/pages/front-desk/recruitment/PositionList.vue'; //岗位信息列表
import PositionDetail from '@/pages/front-desk/recruitment/PositionDetail.vue'; //岗位信息详情

const frontdeskRoutes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage, //HomePage首页
  },
  // ------------------------------------------------------------------------
  {
    path: '/ghs',
    name: 'SupplierEntry',
    component: SupplierEntry, // 入驻通道--供货商入驻
  },
  {
    path: '/lss',
    name: 'ShopEntry',
    component: ShopEntry, // 入驻通道--零售商入驻
  },
  // ------------------------------------------------------------------------
  {
    path: '/xp',
    name: 'Recommend',
    component: Recommend, // 学习中心--选品推荐
  },
  {
    path: '/xp/:id',
    name: 'RecommendDetails',
    component: ActicleDetails, // 学习中心--选品推荐文章详情
  },
  {
    path: '/yy',
    name: 'DryInfo',
    component: DryInfo, // 学习中心--运营干货
  },
  {
    path: '/yy/:id',
    name: 'DryInfoDetails',
    component: ActicleDetails, // 学习中心--运营干货文章详情
  },
  {
    path: '/sp',
    name: 'OperateVideo',
    component: OperateVideo, // 学习中心--操作视频
  },
  // ---------------------------------------------------------------------------
  {
    path: '/storestory',
    name: 'StoreStory',
    component: StoreStory, // 商家故事
  },
  // ---------------------------------------------------------------------------
  {
    path: '/jyzc',
    name: 'BusinessSupport',
    component: BusinessSupport, // 经营支持--综合页面
  },
  {
    path: '/platrule/:id?',
    name: 'PlatRule',
    component: PlatRule, //经营支持--平台规则
  },

  {
    path: '/zj',
    name: 'SolicitationViews',
    component: SolicitationViews, //经营支持--意见征集
  },
  {
    path: '/zj/:id?',
    name: 'SolicitationViewsDetails',
    component: SolicitationViewsDetails, //经营支持--意见征集
  },
  // ---------------------------------------------------------------------------
  // {
  //   path: '/zc',
  //   name: 'PolicyNews',
  //   component: PolicyNews, // 行业动态--政策动态
  // },
  // {
  //   path: '/zc/:id?',
  //   name: 'PolicyNewsDetails',
  //   component: PolicyNewsDetails, // 行业动态--政策动态详情
  // },
  {
    path: '/gn',
    name: 'DomesticNews',
    component: DomesticNews, // 行业动态--国内新闻
  },
  {
    path: '/gn/:id?',
    name: 'DomesticNewsDetails',
    component: DomesticNewsDetails, // 行业动态--国内新闻详情
  },

  {
    path: '/kj',
    name: 'WorldNews',
    component: WorldNews, // 行业动态--跨境资讯
  },
  {
    path: '/kj/:id?',
    name: 'WorldNewsDetails',
    component: WorldNewsDetails, // 行业动态--跨境资讯详情
  },

  {
    path: '/news',
    name: 'CompanyNews',
    component: CompanyNews, // 行业动态--公司新闻
  },
  {
    path: '/newsdetails/:id?',
    name: 'CompanyNewsDetails',
    component: CompanyNewsDetails, // 行业动态--跨境资讯详情
  },
  // ----------------------------------------------------------------------
  {
    path: '/zp',
    name: 'JoinUs',
    component: JoinUs, // 关于我们--加入我们
  },
  {
    path: '/xm',
    name: 'CompanyProfile',
    component: CompanyProfile, // 关于我们--企业简介
  },
  {
    path: '/positionlist',
    name: 'PositionList',
    component: PositionList, // 岗位信息列表
  },
  {
    path: '/positiondetail/:id?',
    name: 'PositionDetail',
    component: PositionDetail, // 岗位信息详情
  },
];

export default frontdeskRoutes;
