
export default {
  data() {
    return {
      list: [
        {
          title: '应聘申请',
          description: '',
          img: require('@/static/assets/images/Application.png'),
        },
        {
          title: '简历筛选',
          description: '',
          img: require('@/static/assets/images/Resume.png'),
        },
        {
          title: '通知初试',
          description: '部分岗位笔试',
          img: require('@/static/assets/images/FirstTry.png'),
        },
        {
          title: '复试',
          description: '部分岗位需二次面试',
          img: require('@/static/assets/images/SecondInterview.png'),
        },
        {
          title: 'offer',
          description: '',
          img: require('@/static/assets/images/offer.png'),
        },
      ],
    };
  },
  methods: {
    /**
     * 页面触发
     */
    goUrl() {
      this.$router.push('/positionlist');
    },
  },
};
