
import ImageShow from '@/pages/front-desk/components/ImageShow.vue'; // 展示图片/视频并预览
import Footer from '@/pages/front-desk/components/footer/Footer.vue'; // 页脚

export default {
  head() {
    return {
      title: this.Title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.Description,
        },
        {
          hid: 'Keywords',
          name: 'Keywords',
          content: this.Keywords,
        },
      ],
      link: [{ rel: 'icon', type: 'image/x-icon', href: 'favicon.ico' }],
    };
  },
  name: 'ActicleDetails',
  components: { ImageShow, Footer },
  data() {
    return {};
  },
  /**
   *
   * @param {Object} app 相当于vue实例
   */
  async asyncData(app) {
    let acticleId = app.params.id; // 文章id
    let details = {}; // 文章详情
    let prevMsg = {}; // 上一篇文章数据
    let nextMsg = {}; // 下一篇文章数据
    let currentPath = ''; //当前页面链接

    // 获取当前路径名称
    if (app?.route?.path) {
      currentPath = app?.route?.path?.split('/')?.[1];
    }
    // TDK
    let Title = '';
    let Keywords = '';
    let Description = '';
    let tdkInfo = {};
    try {
      const [resDetails, resTdk] = await Promise.all([
        app.$getDetail({ id: acticleId }), // 文章详情
        // 获取TDK
        app.$getSeoInfo({ id: '42' }),
      ]);
      if (resDetails?.code == 1) {
        details = resDetails?.data?.list || {};
        prevMsg = resDetails?.data?.previous || {};
        nextMsg = resDetails?.data?.next || {};
      }
      // 详情TDK(后台数据)
      if (resTdk?.code == 1) {
        tdkInfo = resTdk?.data?.[0] || {};
      }

      if (details?.title) {
        // T- 标题
        Title += details?.title;
        // K-关键字
        Keywords += details?.title;
      }
      // T- 标题
      if (tdkInfo?.title) {
        Title += tdkInfo?.title;
      }
      // K-关键字
      if (tdkInfo?.keyword) {
        Keywords += tdkInfo?.keyword;
      }
      // D-描述
      if (details?.description) {
        Description += details?.description?.substring(0, 72);
      }
      return {
        details,
        prevMsg,
        nextMsg,
        Title,
        Keywords,
        Description,
        currentPath,
      };
    } catch (error) {
      console.error(error);
      if (error?.status) {
        // 请求报错
        console.log(
          'front-desk/learning-center/acticle-details',
          error?.status,
          error?.statusText
        );
      } else {
        // 代码报错
        console.log(error);
      }
      return {
        details,
        prevMsg,
        nextMsg,
        Title,
        Keywords,
        Description,
        currentPath,
      };
    }
  },
  computed: {
    // 面包屑导航
    breadList() {
      // 学习中心不一样
      if (this.details?.classify == 'store_story') {
        let storyList = [
          { name: '商家故事', link: '/storestory' },
          { name: '故事详情', link: '' },
        ];
        return storyList;
      }
      let list = [];
      let last = [];
      if (this.details?.classify == 'learning_recommend') {
        last = [
          { name: '选品推荐', link: '/xp' },
          { name: '文章详情', link: '' },
        ];
      }
      if (this.details?.classify == 'learning_operating') {
        last = [
          { name: '运营干货', link: '/yy' },
          { name: '文章详情', link: '' },
        ];
      }
      return list.concat(last);
    },
    // 顶部导航栏需要高亮
    needHighLight() {
      if (
        this.details?.classify == 'learning_recommend' ||
        this.details?.classify == 'learning_operating'
      ) {
        return '学习中心';
      }
      if (this.details?.classify == 'store_story') {
        return '商家故事';
      }
    },
    isPre() {
      let flag = false;
      if (this.details && this.details.images) {
        let arr = this.details.images.split('.');
        let type = arr[arr.length - 1];
        if (type == 'mp4') {
          flag = true;
        }
      }
      return flag;
    },
  },
  mounted() {},
  methods: {},
};
