
// 变量
import { setTdkField } from '../js/seo_table';
// api
import { getSeoList } from '@/api/client-api/backstageapi.js';
// 组件
import EditorTdk from './EditorTdk';
export default {
  components: { EditorTdk },
  data() {
    return {
      curTableFiled: setTdkField, //当前表格配置
      tableData: [], //表格数据
      rowInfo: {}, //选中的表格数据
      // 弹框
      hasEditorDialog: false, //控制编辑TDK弹框
    };
  },
  mounted() {
    this.getSeoList();
  },
  methods: {
    /**
     * 页面触发
     */
    // -------------------------
    // 点击“编辑”
    handleEditor(row) {
      this.rowInfo = row;
      this.hasEditorDialog = true;
    },
    // 关闭编辑弹框
    closeDialog() {
      this.rowInfo = {};
      this.hasEditorDialog = false;
    },
    // 获取列表数据
    async getSeoList() {
      this.closeDialog();
      try {
        let res = await getSeoList();
        if (res?.code == 1) {
          this.tableData = res?.data || [];
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
